import React, { useState, useEffect } from 'react';
import './ourCreations.css';
import { CardContainer } from '../../Components/CardContainer.js';

function OurCreations({dataArray, addToCart, textDataArray, displayLang, displayCurr}){
    const tt = textDataArray._ourCreations;

    return(
        <div id='ourCreationPage'>
            <div className='IS_SectionTitle'>
                <h1>{tt.headerH1[displayLang]}</h1>
                <h2>{tt.headerH2[displayLang]}</h2>
                <p>{tt.HeaderP[displayLang]}</p>
            </div>
            <CardContainer 
                dataArray={dataArray} 
                addToCart={addToCart}
                textDataArray={textDataArray}
                displayLang={displayLang}
                displayCurr={displayCurr}
                />
        </div>
    )
}

export {OurCreations}