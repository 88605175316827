import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './components.css';
import { CardContainerComponents } from '../../Components/CardContainer.js';
import '../../Components/cardContainer.css'

function ComponentsPage({textDataArray, displayLang}){
    const tt = textDataArray._components;
    const ttNav = textDataArray._navBar;

    return(
        <div id='componentsPage'>
            <img className='sectionTitle' src ={require('../../images/components_main_2.jpg')} />
            <div className='cardsSection'>
                <h1>{tt.headerH1[displayLang]}</h1>
                <h2>{tt.headerH2[displayLang]}</h2>
                <p>{tt.headerP[displayLang]}</p>

            </div>
                <div id="mainCardContainer">
                    <div id='cardContainer'>
                        {/* gardé hard-codé puisque ne changera jamais*/}
                        <div className="card">
                            <img src={require('../../images/woodEssence_main_3.jpg')}  alt="keyboard1"/>
                            <ul><li>{ttNav.subPWE[displayLang]}</li></ul>
                            <NavLink className="cardNavLink" to="/components/wood"></NavLink>
                        </div>
                        <div className="card">
                            <img src={require('../../images/PCB_main.jpg')}  alt="keyboard1"/>
                            <ul><li>{ttNav.subPPCB[displayLang]}</li></ul>
                            <NavLink className="cardNavLink" to="/components/pcb"></NavLink>
                        </div>
                        <div className="card">
                            <img src={require('../../images/plate_main.jpg')}  alt="keyboard1"/>
                            <ul><li> {ttNav.subPPlate[displayLang]} </li></ul>
                            <NavLink className="cardNavLink" to="/components/plate"></NavLink>
                        </div>
                        <div className="card">
                            <img src={require('../../images/Switches_main_4.jpg')}  alt="keyboard1"/>
                            <ul><li> {ttNav.subPSwitches[displayLang]} </li></ul>
                            <NavLink className="cardNavLink" to="/components/switches"></NavLink>
                        </div>
                        <div className="card">
                            <img src={require('../../images/K000_6.jpg')}  alt="keyboard1"/>
                            <ul><li> {ttNav.subPKeycaps[displayLang]} </li></ul>
                            <NavLink className="cardNavLink" to="/components/keycaps"></NavLink>
                        </div>
                        <div className="card">
                            <img src={require('../../images/PCB_plate1.jpg')}  alt="keyboard1"/>
                            <ul><li> {ttNav.subPStabs[displayLang]} </li></ul>
                            <NavLink className="cardNavLink" to="/components/stabs"></NavLink>
                        </div>
                        <div className="card">
                            <img src={require('../../images/cable_main.jpg')}  alt="keyboard1"/>
                            <ul><li> {ttNav.subPCables[displayLang]} </li></ul>
                            <NavLink className="cardNavLink" to="/components/cable"></NavLink>
                        </div>
                    </div>     
                </div>
        </div>
    )
}

function ComponentsDetailsPage(props){
    console.log("dans component details page, props dataArray:" + JSON.stringify(props.dataArray));

    return(
        <div id='componentsPage'>
                <img className='sectionTitle' id={props.id} src ={props.backgroundPicture} />
                {/* <div className='bgBlurr'> </div> */}
                <div className='cardsSection'>
                    <h1>{props.title}</h1>
                    <h2>{props.subTitle}</h2>
                    <p>{props.text}
                    </p>
                </div>
            
               
            <CardContainerComponents 
                dataArray={props.dataArray} 
                addToCart={props.addToCart}
                textDataArray={props.textDataArray}
                displayLang={props.displayLang}
                displayCurr={props.displayCurr}
                />
        </div>
    )
}


export {ComponentsPage, ComponentsDetailsPage}