import React, { useState, useEffect, useContext } from 'react';
import './checkout.css';
import { CheckoutForm } from '../../Stripe/Stripe';
import { backendURLContext } from '../../../src/index.js'


function CheckoutPage({cartUUID, textDataArray, displayLang, displayCurr}){
    // const backendURL = useContext(backendURLContext);
    // const [checkoutCart, setCheckoutCart] = useState({});
    //fetch cart information to passt to stripe

  //   useEffect(() => {
  //       async function getCart(){
  //         console.log("dans getCart de chekcoutPage, cartUUID: " + cartUUID);
  //           let updateSucessfull = false;
  //           fetch(backendURL+"/cart/fetchCartFrontEnd", {
  //                 method: 'POST',
  //                 headers: new Headers({'content-type':'application/json'}),
  //                 body: JSON.stringify({
  //                   'UUID': cartUUID,
  //                 })
  //               })  
  //           .then((resObject)=>{
  //             if(!resObject.ok){
  //                 return resObject.json();
  //             } else {
  //               updateSucessfull = true;
  //               return resObject.json();
  //             }
  //           })
  //           .then((res)=>{
  //             setCheckoutCart(res);
  //             console.log("checkoutCart: " + JSON.stringify(res));
  //           })
  //       }
  //       getCart();
  // }, [])

    return(
        <div id='checkoutPage'>
            <div className='space'></div>
            <CheckoutForm 
                cartUUID={cartUUID}
                textDataArray={textDataArray}
                displayLang={displayLang}
                displayCurr={displayCurr}
                />
        </div>
    )
}

export {CheckoutPage}