import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './cardContainer.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ProductOverlay, ProductOverlayComponent } from './ProductOverlay';


// =========== KEYBOARDS ==================
function CardContainer({dataArray, addToCart, textDataArray, displayLang, displayCurr}){
    const [showProducOverlay, setShowProducOverlay] = useState(false);
    const [targetKbd, setTargetKBD] = useState({})

    const handleClick=(kbd)=>{
        setTargetKBD(kbd);
        setShowProducOverlay(true);
    }


    return(
      <section>
         {showProducOverlay? <ProductOverlay 
            onClick={setShowProducOverlay} 
            kbdData={targetKbd}
            textDataArray={textDataArray}
            displayLang={displayLang}
            addToCart={addToCart}
            displayCurr={displayCurr}
            />:<></>}
               
        <div id="mainCardContainer">
            <div id='cardContainer'>
                {/* map each keyboard in the array to create a card */}
                {dataArray.map((kbd)=>
                    <Card 
                        kbdData={kbd} 
                        key={kbd._id} 
                        onClick={handleClick} 
                        addToCart={addToCart}
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                        displayCurr={displayCurr}
                        />
                )}
            </div>     
        </div>
      </section>
    )
  }

  function Card({kbdData, onClick, addToCart, textDataArray, displayLang, displayCurr}){
    const handleClick = (e)=>{
        // do nothing to prevent opening overlay if user click on add to cart
        e.stopPropagation();
        addToCart(kbdData)
      }

    return(
        <div className="card" onClick={()=>{onClick(kbdData)}}>
        <img src={kbdData._coverPicture}  alt="keyboard1"  onClick={()=>{onClick(kbdData)}}/>
        <h1>{kbdData._name[displayLang]}</h1>
            <ul>
                <li>  {/* Form factor */}
                    <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
                    {kbdData._formFactor[displayLang]}
                </li>
                <li>{/* Backplate */}
                    <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
                    {kbdData._woodEssence[displayLang]}
                </li> 
                <li>{/* Plate */}
                    <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
                    {kbdData._plate[displayLang]}
                </li> 
                <li>{/* Price */}
                    <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
                    {kbdData._price[displayCurr]} {textDataArray._displayCurr.currSign[displayCurr]}
                </li> 
            </ul>
        <div class='actionBar'>
            <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart" 
            onClick={handleClick}/>
            <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="fullScreen"/>
        </div>
    </div>
    )
  }

  function StandAloneCard({kbdData, onClick, addToCart, textDataArray, displayLang, displayCurr}){
    console.log("dans stand alone card, displayCurr: " + displayCurr);
    const handleClick = (e)=>{
        // do nothing to prevent opening overlay if user click on add to cart
        e.stopPropagation();
        addToCart(kbdData)
      }

    return(
        <div className='carouselCardContainer'>
            <div className="card" onClick={()=>{onClick(kbdData)}}>
                <img src={kbdData._coverPicture}  alt="keyboard1"/>
                <h1>{kbdData._name[displayLang]}</h1>
                <ul>
                    <li>  {/* Form factor */}
                        <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
                        {kbdData._formFactor[displayLang]}
                    </li>
                    <li>{/* Backplate */}
                        <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
                        {kbdData._woodEssence[displayLang]}
                    </li> 
                    <li>{/* Plate */}
                        <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
                        {kbdData._plate[displayLang]}
                    </li> 
                    <li>{/* Price */}
                        <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
                        {kbdData._price[displayCurr]} {textDataArray._displayCurr.currSign[displayCurr]}
                    </li> 
                </ul>
                <div class='actionBar'>
                    <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"
                    onClick={handleClick}/>
                    <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
                </div>
            </div>
        </div>
    )
  }


  // =========== COMPONENTS ==================
function CardContainerComponents({dataArray, addToCart, textDataArray, displayLang, displayCurr}){
    const [showProducOverlay, setShowProducOverlay] = useState(false);
    const [targetComponent, setTargetComponent] = useState({})

    const handleClick=(component)=>{
        setTargetComponent(component);
        setShowProducOverlay(true);
    }


    return(
      <section>
         {showProducOverlay? <ProductOverlayComponent 
            onClick={setShowProducOverlay} 
            componentData={targetComponent}
            textDataArray={textDataArray}
            displayLang={displayLang}
            addToCart={addToCart}
            displayCurr={displayCurr}
            />:<></>}
               
        <div id="mainCardContainer">
            <div id='cardContainer'>
                {/* map each component in the array to create a card */}
                {dataArray.map((component)=>
                    <CardComponent 
                        componentData={component} 
                        key={component._id} 
                        onClick={handleClick} 
                        addToCart={addToCart}
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                        displayCurr={displayCurr}
                        />
                )}
            </div>     
        </div>
      </section>
    )
  }

  function CardComponent({componentData, onClick, addToCart, textDataArray, displayLang, displayCurr}){
    const handleClick = (e)=>{
        // do nothing to prevent opening overlay if user click on add to cart
        e.stopPropagation();
        addToCart(componentData)
      }

    return(
        <div className="card" onClick={()=>{onClick(componentData)}}>
        <img src={componentData._coverPicture}  alt="keyboard1"  onClick={()=>{onClick(componentData)}}/>
        <h1>{componentData._name[displayLang]}</h1>
            <div className='description'>{componentData._shortDescription[displayLang]}</div>
            <ul className='componentUL'>
                {componentData._shortSpecs[displayLang].map((specBullet)=>
                    <li>
                    <img src={require('../images/Icon_Bullet_C5_4084269.png')}  alt="Price"/>
                    {specBullet}
                    </li>
                )}
                <li>{/* Price */}
                        <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Price"/>
                        {componentData._price[displayCurr]} {textDataArray._displayCurr.currSign[displayCurr]}
                </li> 
            </ul>
        <div class='actionBar'>
            <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart" 
            onClick={handleClick}/>
            <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="fullScreen"/>
        </div>
    </div>
    )
  }

  export {CardContainer, StandAloneCard, CardContainerComponents};



  /*
  
  <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
                    <img src={require('../images/K000_4.jpg')}  alt="keyboard1" onClick={(e)=>{setShowProducOverlay(true)}}/>
                    <h1>{dataArray[0]._name}</h1>
                    <ul>
                    <li>  {/* Form factor */
        //             <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
        //             65% RGB
        //         </li>
        //         <li>{/* Backplate */}
        //             <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
        //             Bubinga standard backplate
        //         </li> 
        //         <li>{/* Plate */}
        //             <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
        //             Red switches - factory lubed
        //         </li> 
        //         <li>{/* Price */}
        //             <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
        //             199 $
        //         </li> 
        //     </ul>
        //     <div class='actionBar'>
        //         <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"/>
        //         <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
        //     </div>
        // </div>
        // <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
        //     <img src={require('../images/K000_5.jpg')}  alt="keyboard1"/>
        //     <ul>
        //     <li>  {/* Form factor */}
        //             <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
        //             65% RGB
        //         </li>
        //         <li>{/* Backplate */}
        //             <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
        //             Bubinga standard backplate
        //         </li> 
        //         <li>{/* Plate */}
        //             <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
        //             Red switches - factory lubed
        //         </li> 
        //         <li>{/* Price */}
        //             <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
        //             199 $
        //         </li> 
        //     </ul>
        //     <div class='actionBar'>
        //         <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"/>
        //         <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
        //     </div>
        // </div>
        // <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
        //     <img src={require('../images/K000_6.jpg')}  alt="keyboard1"/>
        //     <ul>
        //     <li>  {/* Form factor */}
        //             <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
        //             65% RGB
        //         </li>
        //         <li>{/* Backplate */}
        //             <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
        //             Bubinga standard backplate
        //         </li> 
        //         <li>{/* Plate */}
        //             <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
        //             Red switches - factory lubed
        //         </li> 
        //         <li>{/* Price */}
        //             <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
        //             199 $
        //         </li> 
        //     </ul>
        //     <div class='actionBar'>
        //         <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"/>
        //         <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
        //     </div>
        // </div>
        // <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
        //     <img src={require('../images/K000_7.jpg')} alt="keyboard1"/>
        //     <ul>
        //     <li>  {/* Form factor */}
        //             <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
        //             65% RGB
        //         </li>
        //         <li>{/* Backplate */}
        //             <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
        //             Bubinga standard backplate
        //         </li> 
        //         <li>{/* Plate */}
        //             <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
        //             Red switches - factory lubed
        //         </li> 
        //         <li>{/* Price */}
        //             <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
        //             199 $
        //         </li> 
        //     </ul>
        //     <div class='actionBar'>
        //         <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"/>
        //         <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
        //     </div>
        // </div>
        // <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
        //     <img src={require('../images/K000_2.jpg')}  alt="keyboard1"/>
        //     <ul>
        //     <li>  {/* Form factor */}
        //             <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
        //             65% RGB
        //         </li>
        //         <li>{/* Backplate */}
        //             <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
        //             Bubinga standard backplate
        //         </li> 
        //         <li>{/* Plate */}
        //             <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
        //             Red switches - factory lubed
        //         </li> 
        //         <li>{/* Price */}
        //             <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
        //             199 $
        //         </li> 
        //     </ul>
        //     <div class='actionBar'>
        //         <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"/>
        //         <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
        //     </div>
        // </div>
        // <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
        //     <img src={require('../images/K000_2.jpg')}  alt="keyboard1"/>
        //     <ul>
        //     <li>  {/* Form factor */}
        //             <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
        //             65% RGB
        //         </li>
        //         <li>{/* Backplate */}
        //             <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
        //             Bubinga standard backplate
        //         </li> 
        //         <li>{/* Plate */}
        //             <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
        //             Red switches - factory lubed
        //         </li> 
        //         <li>{/* Price */}
        //             <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
        //             199 $
        //         </li> 
        //     </ul>
        //     <div class='actionBar'>
        //         <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"/>
        //         <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
        //     </div>
        // </div>
        // <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
        //     <img src={require('../images/K000_2.jpg')}  alt="keyboard1"/>
        //     <ul>
        //     <li>  {/* Form factor */}
        //             <img src={require('../images/Icon_Keyboard_C5_4911712.png')}  alt="Form Factor"/>
        //             65% RGB
        //         </li>
        //         <li>{/* Backplate */}
        //             <img src={require('../images/Icon_Wood_C5_3928237.png')}  alt="Backplate wood"/>
        //             Bubinga standard backplate
        //         </li> 
        //         <li>{/* Plate */}
        //             <img src={require('../images/Icon_Switch_C5_2987081.png')}  alt="Plate"/>
        //             Red switches - factory lubed
        //         </li> 
        //         <li>{/* Price */}
        //             <img src={require('../images/Icon_PriceTag_C5_6779898.png')}  alt="Plate"/>
        //             199 $
        //         </li> 
        //     </ul>
        //     <div class='actionBar'>
        //         <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart"/>
        //         <img src={require('../images/Icon_FullScreen_C5_6825489.png')} alt="Add to cart"/>
        //     </div>
        // </div>
  