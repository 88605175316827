import React, { useState, useEffect } from 'react';
import './aboutUs.css';

const AboutUsPage=({textDataArray, displayLang})=>{

    const tt = textDataArray._aboutUs;
    
    return(
        <div id='AUPage'>
            <div className='sectionTitle'>
                <h1>{tt.titleH1[displayLang]}</h1>
                <h2>{tt.titleH2[displayLang]}</h2>
            </div>
            <div className='innerText'>
                <p>
                {tt.titleP[displayLang]}
                </p>
            </div>

            <div className='section' id='firstSection'>
                <h1>{tt.firstSectionH1[displayLang]}</h1>
                <p>{tt.firstSectionP[displayLang]}</p>

                {/* <img src={require('../../images/K000_4.jpg')} alt="Hide Nothing"/> */}
            </div>
            <div className=' section AUCardMainContainer'>
                <h1>{tt.visionH1[displayLang]}</h1>
                <div className='AUCardContainer'>
                    <div className='AUCard'>
                        <img src={require('../../images/K0003_4.jpg')} alt="woodWorking"/>
                        {/* https://unsplash.com/photos/person-touching-brown-plank-IQbC4VU4YPQ   */}
                        <div className='cardText'>
                            <p>{tt.visionP[displayLang]}</p>
                            <div className='actButton'>
                                <div className='btn' >{tt.visionBtn[displayLang]}</div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className=' section AUCardMainContainer'>
                <h1>{tt.missionH1[displayLang]}</h1>
                <div className='AUCardContainer'>
                    <div className='AUCard'>
                        <img src={require('../../images/K0002_4.jpg')} alt="woodWorking"/>
                        {/* https://unsplash.com/photos/person-touching-brown-plank-IQbC4VU4YPQ   */}
                        <div className='cardText'>
                            <p>{tt.missionP[displayLang]}</p>
                            <div className='actButton'>
                                <div className='btn' >{tt.missionBtn[displayLang]}</div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className='section'>
                <h1>{tt.uniqueH1[displayLang]} </h1>
                <p>{tt.uniqueP[displayLang]}</p>
                {/* <img src={require('../../images/K000_4.jpg')} alt="Hide Nothing"/> */}
            </div>
            <div className='section bigPict'>
                <img src={require('../../images/inStock_main.jpg')} alt="woodWorking"/>
                
            </div>
            <div className='section lastSection' >
                <h1>{tt.lastSectionH1[displayLang]} </h1>
                <p>{tt.lastSectionP[displayLang]} </p>
            </div>
        </div>
    )
}

export{AboutUsPage};
