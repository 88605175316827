import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Form, NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import './cart.css';
import { backendURLContext } from '../../index.js'
import { AddressAutofill } from '@mapbox/search-js-react';
import queryString from 'query-string';

function Cart({cart,curr,removeFromCart, setShippingOptions, setCart, textDataArray, displayLang, btnEnable, 
animateModal, animateModalLoader, clearModalLoader, updateShippingOptions, setUpdateShippingOptions}) {
    const [rateArray, setRateArray] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true)
    const [missingItemsModal, setMissingItemsModal] = useState(false);
    const [overloadModal, setOverloadModal] = useState(false);
    const [missingItemsArray, setMissingItemsArray] = useState([]);
    const navigate = useNavigate();
    const urls = useContext(backendURLContext);
    const backendURL = urls.backend;
    const frontendURL = urls.frontend;
    // console.log("dans cart: " + JSON.stringify(cart));
    const [customBuildToShow, setCustomBuildToShow] = useState(false);
    const tt = textDataArray._cart;
    const location = useLocation();


    useEffect(() => {
        async function loadCartPage(){
            //check if user is coming from refered URL sent by email in response to custom build quote request
            // const queryString = window.location.search;
            const urlParams = new URLSearchParams(location.search);
            const cartUUID = urlParams.get('cartUUID');
            // console.log("dans use effect du cart, URL params cartUUDI: " + cartUUID);


            if(cartUUID){
                console.log("dans if cart UUID: ");
                await fetchCart(cartUUID);
                console.log("avant set is empty");
                setIsEmpty(false);
                setCustomBuildToShow(true);
            } else{
            //manage empty cart
                if(cart._itemCount > 0 || cart._buildRequest.length > 0){
                    setIsEmpty(false);
                } else {
                    console.log("dans if cart empty");
                    setIsEmpty(true);
                }   
            }

            //Check if there is custom build to show. 
            cart._buildRequest.map((item)=>{
                if(item._status == 'Submitted' || item._status == 'In Progress' || item._status == 'Quote submitted'  ){
                    setCustomBuildToShow(true);
                }
             }
            ) 
        }

        loadCartPage()

    }, [isEmpty])
    
    const fetchCart=async(cartUUID)=>{
        let updateSucessfull = false;
        console.log("dans fetchCart de la page cart");
        await fetch(backendURL+"/cart/fetchCartFrontEnd", {
              method: 'POST',
              headers: new Headers({'content-type':'application/json'}),
              body: JSON.stringify({
                'UUID':cartUUID,
              })
            })  
        .then((resObject)=>{
          if(!resObject.ok){
              return resObject.json();
          } else {
            updateSucessfull = true;
            return resObject.json();
          }
        })
        .then((res)=>{
            if(updateSucessfull){
                localStorage.setItem('cart',JSON.stringify(res));
                setCart(res);
                // console.log("a la fin du fetchCartFrontend, dans le local storage: " + JSON.stringify(res));
            }
        })
  }

    const checkout=()=>{
        // call backend to verify everything is in stock
        let updateSucessfull = false, overload = false;
        fetch(backendURL+"/cart/checkInventory", {
                method: 'POST',
                headers: new Headers({'content-type':'application/json'}),
                body: JSON.stringify({
                'UUID': cart._UUID, 
                'lang':displayLang 
                })
            })  
        .then((resObject)=>{
            if(!resObject.ok){
                return resObject.json();
            } else {
            updateSucessfull = true;
            return resObject.json();
            }
        })
        .then(async (res)=>{
            if(res.missingItem){
                setMissingItemsModal(true);
                setMissingItemsArray(res.missingItemsArray)
            } else{
                overload = await checkOverload();
                // console.log("result du retour de checkOverlaod, donc dans checkout: " + overload );
                if(overload){
                    setOverloadModal(true);
                }
                else{navigate("/checkout")}
            }
        })
    }

    const checkOverload= async ()=>{
        // call backend to verify workload
        let overload = false;
        try{
            const response = await fetch(backendURL+"/cart/checkoverload");
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }
            overload = await response.json();
            return overload;
        }
        catch (err){
            console.error(err.message);
            return (false);
        }
    }

    const acceptMissing=()=>{
        let overload = false;
        console.log("dans accept missing frontend");
        fetch(backendURL+"/cart/acceptMissingItem", {
            method: 'POST',
            headers: new Headers({'content-type':'application/json'}),
            body: JSON.stringify({
            'UUID': cart._UUID 
            })
        }).then(async (resObject)=>{
            setMissingItemsModal(false);
            overload = await checkOverload();
            if(overload){
                setOverloadModal(true);
            }
            else{navigate("/checkout")}
        })  
    }

    const acceptWaitingList= async ()=>{
        //Check if email is valid
        let email = document.querySelector('#overloadEmail').value;
        // console.log("email:  " + email);
        let validatedEmail = await validateEmail(email);
        if (validatedEmail === null){
            console.log("invalid email");
            animateModal(textDataArray._modal.invalidEmail[displayLang], 2000)
        } else{
            animateModalLoader(textDataArray._modalLoader.waitingList[displayLang])
            // call backend to request quotation 
            let updateSucessfull = false;
            fetch(backendURL+"/cart/acceptWaitingList", {
                method: 'POST',
                headers: new Headers({'content-type':'application/json'}),
                body: JSON.stringify({
                    'UUID': cart._UUID,
                    'customerEmail': email,
                    'lang':displayLang
                })
            })  
            .then((resObject)=>{
                if(!resObject.ok){
                    return resObject.json();
                } else {
                    updateSucessfull = true;
                    return resObject.json();
                }
            })
            .then((res)=>{
                clearModalLoader();
                localStorage.setItem('cart',JSON.stringify(res));
                setCart(res);
                animateModal(textDataArray._modal.waitingListRequest[displayLang], 3000)

                navigate("/")
            })
        }
    }

    const closeMissing=()=>{
        setMissingItemsModal(false);
    }

    const closeOverload=()=>{
        setOverloadModal(false);
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };


    return(
        <div id='cartPageContainer'>

            {
            missingItemsModal? <MissingItemModal 
                acceptMissing={acceptMissing}
                closeMissing={closeMissing}
                missingItemsArray={missingItemsArray}
                textDataArray={textDataArray}
                displayLang={displayLang}
                />:
                overloadModal? <OverloadModal
                    textDataArray={textDataArray}
                    displayLang={displayLang}
                    acceptMissing={acceptMissing}
                    closeOverload={closeOverload}
                    acceptWaitingList={acceptWaitingList}
                />:
            <>
            <div className='sectionTitle'>
                <h1>{tt.titleH1[displayLang]}</h1>
            </div>
            <div id="cartContent">
                {isEmpty? 
                <>{tt.isEmpty[displayLang]}</>:
                <>
                {customBuildToShow? <h2>{tt.inStockH2[displayLang]}</h2>:<></>}
                {cart._content.map((item)=>
                    <CartItemRow 
                        UUID={cart._UUID} 
                        item={item} 
                        curr={curr} 
                        removeFromCart={removeFromCart} 
                        showDel={true}
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                        />
                )}
                {customBuildToShow? <><h2>{tt.customBuildH2[displayLang]}</h2>
                <p>{tt.customBuildP[displayLang]}</p></>
                :<></>}
                 {cart._buildRequest.map((item)=>{
                    // console.log("item du map: " + JSON.stringify(item));
                    if(item._status == 'Submitted' || item._status == 'In Progress' || item._status == 'Quote submitted'  ){
                        return ( <CartItemRow 
                                    UUID={cart._UUID} 
                                    item={item._keyboard} 
                                    curr={curr} 
                                    removeFromCart={removeFromCart} 
                                    showDel={false}
                                    textDataArray={textDataArray}
                                    displayLang={displayLang}
                                    />)
                    }
                 }

                )}
                 <div className='subTotalRow'>
                    <p>{tt.subtotal[displayLang]} ({cart._itemCount} items): {cart._subtotal[curr]} {textDataArray._displayCurr.currSign[curr]}</p>
                </div>
                <ShippingForm 
                    ratearray={rateArray} 
                    setRateArray={setRateArray} 
                    cartUUID={cart._UUID} 
                    setShippingOptions={setShippingOptions}
                    textDataArray={textDataArray}
                    displayLang={displayLang}
                    curr={curr}
                    animateModal={animateModal}
                    updateShippingOptions = {updateShippingOptions}
                    setUpdateShippingOptions = {setUpdateShippingOptions}
                    />
                <div className='checkoutBtn'>
                    <button 
                        type='button' 
                        className="componentOptionButton"  
                        id="checkoutBtn"
                        onClick={checkout} 
                        disabled={btnEnable}
                        >
                            {tt.ckechkoutBtnMsg[displayLang]} 
                    </button>
                </div>
                </>
                }
            
            </div>
            </>
            }
        </div>    
        
    )
}

function CartItemRow({UUID, item, curr, removeFromCart, showDel, textDataArray, displayLang}){
    // console.log("dans cartItemRow, item: " + JSON.stringify(item));
    const tt = textDataArray._cart;

    // console.log("item name: " + item._keyboard['_name']);
    return(
        <div className='cartItemRow'>
            
            <div className='cartImgContainer'>
                {showDel? <img className='cartImg' src={item._coverPicture} alt="Cart Item"/>
                : <img className='cartImg'src={require('../../images/Icon_CartBYOwoodworking_C5_5264092.png')}
                 alt="Custom Build Request"/>}
                
                <p>{item._name[displayLang]}</p>
                {/* <p>{item._componentType[displayLang]}</p> */}
            </div>
            <div className='cartDesc'>
                {item._type=="keyboard"?<>
                    <ul>
                        <li>  {/* Form factor */}
                            <img src={require('../../images/Icon_Keyboard_C5_4911712.png')} alt="Form Factor"/>
                            {item._formFactor[displayLang]}
                        </li>
                        <li>{/* Wood */}
                            <img src={require('../../images/Icon_Wood_C5_3928237.png')} alt="Backplate wood"/>
                            {item._woodEssence[displayLang]}
                        </li> 
                        <li>{/* Plate */}
                            <img src={require('../../images/Icon_Plate_C5_4033663.png')} alt="Plate"/>
                            {item._plate[displayLang]}
                        </li> 
                        <li>{/* Switches and lube */}
                            <img src={require('../../images/Icon_Switch_C5_2987081.png')} alt="Switch"/>
                            {item._switches[displayLang]}
                        </li>
                        <li>{/* Stabs */}
                            <img src={require('../../images/Icon_Stabs_C5_4965548.png')} alt="Keycaps"/>
                            {item._stabs[displayLang]}
                        </li>
                        <li>{/* Keycaps */}
                            <img src={require('../../images/Icon_Keycap_C5_3612734.png')} alt="Keycaps"/>
                            {item._keycaps[displayLang]}
                        </li>
                        <li>{/* USB cable */}
                            <img src={require('../../images/Icon_USB_C5_1495628.png')} alt="USB cable"/>
                            {item._cable[displayLang]}
                        </li>
                        <li>{/* Accessories */}
                            <img src={require('../../images/Icon_Accessories_C5_6476442.png')} alt="USB cable"/>
                            {item._accessories[displayLang]}
                        </li>
                    </ul>
                <div className='customizationDetails'>
                    <span className='customizationDetailsBold'>{tt.customDetails[displayLang]}</span>
                    <span className='customizationDetailsNormal'>
                        {item._specialRequests[displayLang]}
                    </span>
                </div>
                </>:
                <>
                    <ul>
                        {item._shortSpecs[displayLang].map((specBullet)=>
                            <li>
                            <img src={require('../../images/Icon_Bullet_C5_4084269.png')}  alt="Price"/>
                            {specBullet}
                            </li>
                        )}
                    </ul>
                    
                    <div className='customizationDetails'>
                        <span className='customizationDetailsNormal'>
                            {item._shortDescription[displayLang]}
                    </span>
                </div>
                </>}
            </div>
            <div className='cartPrice'>
                <div className='priceContainer'>
                    <img src={require('../../images/Icon_PriceTag_C5_6779898.png')} alt="Currency"/>
                    <p>{item._price[curr]} {textDataArray._displayCurr.currSign[curr]}</p>
                </div>
                {showDel? <img src={require('../../images/Icon_CartDel_C5_6701491.png')} alt="Remove from cart"
                onClick={(e)=>{removeFromCart(UUID, item._id)}}/>:<></>}
            </div> 
        </div>
    )
}


function ShippingForm({ratearray, setRateArray, cartUUID, setShippingOptions, textDataArray, displayLang, 
    curr, animateModal, updateShippingOptions, setUpdateShippingOptions}){
    const urls = useContext(backendURLContext);
    const backendURL = urls.backend;
    const frontendURL = urls.frontend;
    const [shippingDateReady, setShippingDataReady] = useState(false);
    const [token, setToken] = useState("");
    const [formVal, setFormVal] = useState("");
    const tt = textDataArray._cart;

    

    let easyShipForm = {
        country_alpha2: '',
        country_name:'',
        line_1: '',
        state: '',
        city: '',
        postal_code: '',
        contact_name: '',
        contact_phone: '',
        contact_email: ''
    }
    let checkSum = 0;

//CALL A SECURISER !!! LE ENDPOINT DU BACKEND EST A DECOUVERT, N'IMPORTE QUI PEUT LE CALLER ET OBTENIR LE TOKEN
//DANS LE DASHBOARD DE MAPBOX, SETTER UN URL POUR MA CLE API ????

    useEffect(()=>{
        async function getToken(){
            let fetchedToken = await fetch(backendURL+"/easyship/getToken");
            fetchedToken = await fetchedToken.json();
            // console.log("Token: " + fetchedToken);
            setToken(fetchedToken)

            // await fetchMapboxToken();
            // console.log("Dans use effect, token: " + token);
            }

        getToken();

      }, [])


    useEffect(()=>{    //to debounce input in shipping form
        const getData = setTimeout(()=>{
            handleInput()
        },1500)

        return () => clearTimeout(getData)
    },[formVal])

    useEffect(()=>{    //update shipping option after currency is changed
        if(updateShippingOptions){
            handleInput();
            setUpdateShippingOptions(false);
        }
    },[updateShippingOptions])

    const getInputValues= async()=>{        
        let inputFields = document.querySelectorAll(".eaForm"), name="";
        checkSum =0;checkSum =0;
        inputFields.forEach((field)=>{
            let value = field.value;
            field.name=="line_1 address-search"? name="line_1": name = field.name 
            // console.log("data: " + value+ " name: " + name);
            easyShipForm[name]=value;
            value != ""? checkSum+=1:checkSum+=0;
        })

        // console.log("a la fin de handleinputchage, easyShipForm: " + JSON.stringify(easyShipForm));
        // console.log("checksum: " + checkSum);
        return("done");
    }

    const callBackendGetRate = async(cartUUID)=>{
        console.log("dans call backend Get Rate, avant call a fetch");
        setShippingDataReady(false);
        //manage front-end visual
        let initialInviteElem = document.querySelector('#initialInvite');
        let findingRatesElem = document.querySelector('#findingRates');
        // console.log("classlists: " + document.querySelector('#findingRates').classList);
        try{
            if(initialInviteElem.classList.contains("show")){
                initialInviteElem.classList.remove("show");
                initialInviteElem.classList.add("hide");
            } 
            if(findingRatesElem.classList.contains("hide")){
                findingRatesElem.classList.add('show');
                findingRatesElem.classList.remove("hide");   
            }  
            document.querySelector('#shippingOptionsInviteText').classList.add("loaderActive");
        }
        catch{

        }
        //call backend
        let updateSucessfull = false;
            fetch(backendURL+"/easyship/getRate",{
                method: 'POST',
                headers: new Headers({'content-type':'application/json'}),
                body: JSON.stringify({
                  'destinationData':easyShipForm,
                  'cartUUID': cartUUID,
                  'currency':curr,
                  'lang':displayLang
                  })
                })    
              .then((resObject)=>{
                console.log("DAns le .then, resObject ok? " + resObject.ok);
                if(!resObject.ok){
                    // console.log("erreur dans get Rate: "+ resObject.text());
                    return resObject.json()
                    .then(err=>{
                        let errDetails = err.error.details[0]
                        console.log("dans le .then du error, errDetails:" + errDetails);
                        //trigger le modal
                        // animateModal(textDataArray._modal.addedToCart[displayLang], 1800)
                        if(errDetails == "destination_address.contact_email is invalid"){
                            animateModal(tt.getRateErrEmail[displayLang], 4000)
                        } else{
                            animateModal(tt.getRateErr[displayLang], 6000)
                        }

                            //manage front-end visual
                            try{
                                if(initialInviteElem.classList.contains("hide")){
                                    initialInviteElem.classList.remove('hide');
                                    initialInviteElem.classList.add("show");
                                } 
                                if(findingRatesElem.classList.contains("show")){
                                    findingRatesElem.classList.add('hide');
                                    findingRatesElem.classList.remove("show");    
                                } 
                                if(document.querySelector('#shippingOptionsInviteText').classList.contains("loaderActive")){
                                    document.querySelector('#shippingOptionsInviteText').classList.remove("loaderActive");   
                                }                      
                            }
                            catch{

                            }


                    })
                } else {
                  updateSucessfull = true;
                  return resObject.json()
                  .then((res)=>{
                    //   console.log(res);
                    console.log("dans .then final du callbackend get rate");
                      setRateArray(res);
                      setShippingDataReady(true);
                      if(document.querySelector('#shippingOptionsInviteText')){
                        document.querySelector('#shippingOptionsInviteText').classList.remove("loaderActive");
                      }
                      
                  })
                }
              })
        
    }


    const handleInput=async ()=>{
        // console.log("dans onclick, target: " + e.target);
        await getInputValues();
        if(checkSum == 9){
            await callBackendGetRate(cartUUID);
        }

    }


    return (
        <div className='shippingForm'>
            <div className='shippingInfoSectionContainer'>
                <h1>{tt.shippingFormH1[displayLang]}</h1>
                <form>
                    <AddressAutofill accessToken={token}>
                        <label for="address">
                            {tt.shippingFormAddress[displayLang]}
                            <input
                            id="address" name="line_1" type="text" className='eaForm'
                            autoComplete="address-line1" placeholder={tt.shippingFormAddress[displayLang]}
                            onChange={(e)=> setFormVal(e.target.value)}
                            />
                        </label>
                    </AddressAutofill>
                    <label className='input_label' for='city'>
                        {tt.shippingFormCity[displayLang]}
                        <input
                        id="city" name="city" type="text" className='eaForm'
                        autoComplete="address-level2" placeholder={tt.shippingFormCity[displayLang]}
                        onChange={(e)=> setFormVal(e.target.value)}
                        />
                    </label>
                    <label className='input_label' for='state'>
                        {tt.shippingFormState[displayLang]}
                        <input
                            id="state" name="state" placeholder={tt.shippingFormState[displayLang]} type="text" 
                            autoComplete="address-level1" className='eaForm'
                            onChange={(e)=> setFormVal(e.target.value)}
                        />
                    </label>
                     <label className='input_label' for='country'>
                        {tt.shippingFormCountry[displayLang]}
                        <input
                            id="country" name="country_name" placeholder={tt.shippingFormCountry[displayLang]} type="text"
                            autoComplete="country-name" className='eaForm'
                            onChange={(e)=> setFormVal(e.target.value)}
                        />
                    </label>

                    <label className='input_label' for='country_code' style={{display: "block"}}>
                        {tt.shippingFormCountryCode[displayLang]}
                        <input id="country_code" name="country_alpha2" placeholder={tt.shippingFormCountryCode[displayLang]} 
                            autoComplete="country" className='eaForm' type="text" list="countyCodeDataLlist"
                            onChange={(e)=> setFormVal(e.target.value)}
                        />
                         <datalist id="countyCodeDataLlist" >
                            {tt.CountriesCodes.map((code)=>{
                                return(<option value={code}/>)
                            })}
                            </datalist>
                    </label>

                    <label className='input_label' for='postcode'>
                        {tt.shippingFormPostCode[displayLang]}
                        <input
                            id="postcode" name="postal_code" placeholder={tt.shippingFormPostCode[displayLang]} type="text"
                            autoComplete="postal-code" className='eaForm'
                            onChange={(e)=> setFormVal(e.target.value)}
                        />
                    </label>
                    <label className='input_label' for='name'>
                        {tt.shippingFormName[displayLang]}
                        <input
                            id="name" name="contact_name" placeholder={tt.shippingFormName[displayLang]} type="text"
                            autoComplete="name" className='eaForm'
                            onChange={(e)=> setFormVal(e.target.value)}
                        />
                    </label>
                    <label className='input_label' for='email'>
                        {tt.shippingFormEmail[displayLang]}
                        <input
                            id="email" name="contact_email" placeholder={tt.shippingFormEmail[displayLang]} type="email"
                            autoComplete="email" className='eaForm'
                            onChange={(e)=> setFormVal(e.target.value)}
                        />
                    </label>
                    <label className='input_label' for='phone'>
                        {tt.shippingFormPhone[displayLang]}
                        <input
                            id="phone" name="contact_phone" placeholder={tt.shippingFormPhone[displayLang]} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            autoComplete="tel" className='eaForm'
                            onChange={(e)=> setFormVal(e.target.value)}
                        />
                    </label>
                </form>
            </div>
            <div className='shippingPricesSectionContainer'>
                <h1>{tt.shippingOptionsH1[displayLang]}</h1>
                <div class="radio-input">
                    {shippingDateReady?
                        <ShippingOptions 
                            ratearray={ratearray} 
                            setShippingOptions={setShippingOptions} 
                            textDataArray={textDataArray}
                            displayLang={displayLang}
                            curr={curr}
                        />:
                        <ShippingOptionsInviteText                     
                            textDataArray={textDataArray}
                            displayLang={displayLang}
                        /> 
                        }
                    
                </div>


            </div>
        </div>
    )
}

function ShippingOptionsInviteText({textDataArray, displayLang}){
    const tt = textDataArray._cart;

    return(
        <>
            <input value="value-0" name="value-radio" id="value-0" type="radio" />
            <label for="value-0" className='loader' id='shippingOptionsInviteText'>
                <div class="price">
                    <div id="initialInvite" className='show'>{tt.shippingOptionsInviteTextmissing[displayLang]}</div>
                    <div id="findingRates" className='hide'>{tt.shippingOptionsInviteTextWorking[displayLang]}</div>
                </div> 
            </label>
        </>
    )
}

function ShippingOptions({ratearray, setShippingOptions, textDataArray, displayLang, curr}){
    // console.log("dans ShippingOptions component, rateArray: " + JSON.stringify(ratearray));
    const tt = textDataArray._cart;

    return(
        <>

            {/* Cheapest */}
            <input value="Cheapest" name="value-radio" id="Cheapest" type="radio" onClick={(e)=>{setShippingOptions(ratearray.cheapest.optionUUID)}}/>
            <label for="Cheapest">
                <div class="text">
                    <div class="circle"></div>
                    {ratearray.cheapest.courier_name}
                </div>
                <div class="price">
                    {/* Faut que je gere le currency sign dynamiquement en fonction de la curr selectionnee */}
                    <div>{ratearray.cheapest.total_charge} {textDataArray._displayCurr.currSign[curr]}</div>
                    <div class="small">
                        {tt.shippingIn[displayLang]} <ShippingTime s={ratearray.cheapest.min_delivery_time} f={ratearray.cheapest.max_delivery_time} textDataArray={textDataArray}
                            displayLang={displayLang}/>
                        </div>
                </div> 
                <div class="info">{tt.cheapest[displayLang]}</div>
            </label>
            {/* Cheapest1 */}
            <input value="Cheapest1" name="value-radio" id="Cheapest1" type="radio" onClick={(e)=>{setShippingOptions(ratearray.cheapest1.optionUUID)}} />
            <label for="Cheapest1">
                <div class="text">
                    <div class="circle"></div>
                    {ratearray.cheapest1.courier_name}
                </div>
                <div class="price">
                    {/* Faut que je gere le currency sign dynamiquement en fonction de la curr selectionnee */}
                    <div>{ratearray.cheapest1.total_charge} {textDataArray._displayCurr.currSign[curr]}</div>
                    <div class="small">
                        {tt.shippingIn[displayLang]} <ShippingTime s={ratearray.cheapest1.min_delivery_time} f={ratearray.cheapest1.max_delivery_time} textDataArray={textDataArray}
                            displayLang={displayLang}/>
                        </div>
                </div> 
            </label>

            {/* Cheapest2 */}
            <input value="Cheapest2" name="value-radio" id="Cheapest2" type="radio" onClick={(e)=>{setShippingOptions(ratearray.cheapest2.optionUUID)}}/>
            <label for="Cheapest2">
                <div class="text">
                    <div class="circle"></div>
                    {ratearray.cheapest2.courier_name}
                </div>
                <div class="price">
                    {/* Faut que je gere le currency sign dynamiquement en fonction de la curr selectionnee */}
                    <div>{ratearray.cheapest2.total_charge} {textDataArray._displayCurr.currSign[curr]}</div>
                    <div class="small">
                        {tt.shippingIn[displayLang]} <ShippingTime s={ratearray.cheapest2.min_delivery_time} f={ratearray.cheapest2.max_delivery_time} textDataArray={textDataArray}
                            displayLang={displayLang}/>
                        </div>
                </div> 
            </label>
            {/* fastest */}
            <input value="fastest" name="value-radio" id="fastest" type="radio" onClick={(e)=>{setShippingOptions(ratearray.fastest.optionUUID)}} />
            <label for="fastest">
                <div class="text">
                    <div class="circle"></div>
                    {ratearray.fastest.courier_name}
                </div>
                <div class="price">
                    {/* Faut que je gere le currency sign dynamiquement en fonction de la curr selectionnee */}
                    <div>{ratearray.fastest.total_charge} {textDataArray._displayCurr.currSign[curr]}</div>
                    <div class="small">
                        {tt.shippingIn[displayLang]} <ShippingTime s={ratearray.fastest.min_delivery_time} f={ratearray.fastest.max_delivery_time} textDataArray={textDataArray}
                            displayLang={displayLang}/> 
                        </div>
                </div> 
                <div class="info">{tt.fastest[displayLang]}</div>
            </label>
            <p>{tt.buildTime[displayLang]}</p>
        </>
    )
}

function ShippingTime({s,f, textDataArray, displayLang}){
    const tt = textDataArray._cart;

    if(s!=f){
        return(
            <>
            {s} {tt.to[displayLang]} {f} {tt.days[displayLang]}.
            </>
        )
    } else if (s==1){
        return(
            <>
            1 {tt.day[displayLang]}.
            </>
        )
    } else{
        return(
            <>
            {s} {tt.days[displayLang]}.
            </>
        )
    }
}

function MissingItemModal({missingItemsArary, acceptMissing, closeMissing,missingItemsArray, textDataArray, displayLang }){
    const tt = textDataArray._cart;


    return(
        <div id="producOverlayContainerMIR" className='cartMIR'>
            <div>
                <h1>{tt.missingModalH1[displayLang]}</h1>
            </div>
            <p>{tt.mMP1[displayLang]}</p>
            <ul>
            {missingItemsArray.map((item)=>
                    <li>{item}</li>
            )}
            </ul>
            <p>{tt.mMP2[displayLang]}</p>

            <div className='btnContainerRow'>
                <div className='checkoutBtn'>
                    <button type='button' className="componentOptionButton"  onClick={acceptMissing}>{tt.mMBtnAccept[displayLang]}</button>
                </div>
                <div className='checkoutBtn'>
                    <button type='button' className="componentOptionButton"  onClick={closeMissing}>{tt.mMBtnBack[displayLang]}</button>
                </div>
            </div>
        </div>
    )
}

function OverloadModal({textDataArray, displayLang, acceptWaitingList, closeOverload }){
    const tt = textDataArray._cart;
    return(
        <div id="producOverlayContainerMIR" className='cartMIR'>
            <div>
                <h1>{tt.overloadModalH1[displayLang]}</h1>
            </div>
            <p>{tt.oLP[displayLang]}</p>
            <label className='input_label' for='overloadEmail'>
            {tt.oLEmailLabel[displayLang]}
            </label>
                <input
                    id="overloadEmail" name="overloadContact_email" placeholder={tt.shippingFormEmail[displayLang]} type="email"
                    autoComplete="email"
                    onChange={(e)=> console.log("f")}
                />
            <div className='btnContainerRow'>
                <div className='checkoutBtn'>
                    <button type='button' className="componentOptionButton"  onClick={acceptWaitingList}>{tt.oLAccept[displayLang]}</button>
                </div>
                <div className='checkoutBtn'>
                    <button type='button' className="componentOptionButton"  onClick={closeOverload}>{tt.mMBtnBack[displayLang]}</button>
                </div>
            </div>
        </div>
    )
}

export {Cart}




// <div className='cartItemRow'>
//                     <div className='cartImgContainer'>
//                         <img className='cartImg' src={require('../../images/K0000_2.jpg')} alt="keyboard1"/>
//                         <p>In stock Keyboard</p>
//                     </div>
//                     <div className='cartDesc'>
//                         <ul>
//                             <li>  {/* Form factor */}
//                                 <img src={require('../../images/Icon_Keyboard_C5_4911712.png')} alt="Form Factor"/>
//                                 65% RGB
//                             </li>
//                             <li>{/* Wood */}
//                                 <img src={require('../../images/Icon_Wood_C5_3928237.png')} alt="Backplate wood"/>
//                                 Bubinga
//                             </li> 
//                             <li>{/* Plate */}
//                                 <img src={require('../../images/Icon_Plate_C5_4033663.png')} alt="Plate"/>
//                                 FR4 plate - red
//                             </li> 
//                             <li>{/* Stabs */}
//                                 <img src={require('../../images/Icon_Stabs_C5_4965548.png')} alt="Keycaps"/>
//                                 Durock black hand lubed stabs
//                             </li>
//                             <li>{/* Switches and lube */}
//                                 <img src={require('../../images/Icon_Switch_C5_2987081.png')} alt="Switch"/>
//                                 Gray switches - Factory lube
//                             </li>
//                             <li>{/* Keycaps */}
//                                 <img src={require('../../images/Icon_Keycap_C5_3612734.png')} alt="Keycaps"/>
//                                 Black and Red - OEM
//                             </li>
//                             <li>{/* USB cable */}
//                                 <img src={require('../../images/Icon_USB_C5_1495628.png')} alt="USB cable"/>
//                                 Braided cable - red
//                             </li>
//                             <li>{/* Accessories */}
//                                 <img src={require('../../images/Icon_Accessories_C5_6476442.png')} alt="USB cable"/>
//                                 Case and wrist wrest
//                             </li>
//                         </ul>
//                         <div className='customizationDetails'>
//                             <span className='customizationDetailsBold'>Customization details: </span>
//                             <span className='customizationDetailsNormal'>
//                                 As per request in your quote, your keyboard will be built with a larger 
//                                 backplate, the keycaps we agreed on by email, and a specific keymap as requested.
//                                 We're sure you'll enjoy your very own and unique keyboard!
//                                 Thanks !
//                             </span>
//                         </div>
//                     </div>
//                     <div className='cartPrice'>
//                         <div className='priceContainer'>
//                             <img src={require('../../images/Icon_PriceTag_C5_6779898.png')} alt="$$"/>
//                             <p>179 $</p>
//                         </div>
//                         <img src={require('../../images/Icon_CartDel_C5_6701491.png')} alt="Add to cart"/>
//                     </div>
//                 </div>
//                 <div className='cartItemRow'>
//                     <div className='cartImgContainer'>
//                         <img className='cartImg' src={require('../../images/K0000_2.jpg')} alt="keyboard1"/>
//                         <p>Build your own keyboard</p>
//                     </div>
//                     <div className='cartDesc'>
//                         <ul>
//                             <li>  {/* Form factor */}
//                                 <img src={require('../../images/Icon_Keyboard_C5_4911712.png')} alt="Form Factor"/>
//                                 65% RGB
//                             </li>
//                             <li>{/* Backplate */}
//                                 <img src={require('../../images/Icon_Wood_C5_3928237.png')} alt="Backplate wood"/>
//                                 Bubinga
//                             </li> 
//                             <li>{/* Plate */}
//                                 <img src={require('../../images/Icon_Plate_C5_4033663.png')} alt="Plate"/>
//                                 FR4 plate - red
//                             </li> 
//                             <li>{/* Switches and lube */}
//                                 <img src={require('../../images/Icon_Switch_C5_2987081.png')} alt="Switch"/>
//                                 Gray switches - Factory lube
//                             </li>
//                             <li>{/* Keycaps */}
//                                 <img src={require('../../images/Icon_Keycap_C5_3612734.png')} alt="Keycaps"/>
//                                 Black and Red - OEM
//                             </li>
//                             <li>{/* USB cable */}
//                                 <img src={require('../../images/Icon_USB_C5_1495628.png')} alt="USB cable"/>
//                                 Braided cable - red
//                             </li>
//                             <li>{/* Keycaps */}
//                                 <img src={require('../../images/Icon_Keycap_C5_3612734.png')} alt="Keycaps"/>
//                                 Black and Red - OEM
//                             </li>
//                             <li>{/* USB cable */}
//                                 <img src={require('../../images/Icon_USB_C5_1495628.png')} alt="USB cable"/>
//                                 Braided cable - red
//                             </li>
//                         </ul>
//                         <div className='customizationDetails'>
//                             <span className='customizationDetailsBold'>Customization details: </span>
//                             <span className='customizationDetailsNormal'>
//                                 No customization request
//                             </span>
//                         </div>
//                     </div>
//                     <div className='cartPrice'>
//                         <div className='priceContainer'>
//                             <img src={require('../../images/Icon_PriceTag_C5_6779898.png')} alt="$$"/>
//                             <p>179 $</p>
//                         </div>
//                         <img src={require('../../images/Icon_CartDel_C5_6701491.png')} alt="Add to cart"/>
//                     </div>
//                 </div>
