import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './sideNavBar.css';

const SideNavBar=({textDataArray, displayLang})=>{
    const tt = textDataArray._navBar;
    
    const closeSNB=()=>{
        document.querySelector(".SNBContainer").classList.remove("open")
        document.querySelector("#hamburgerMenu").checked = false;
    }

    const showHideSubMenu=(id)=>{
        console.log("dans show hide sub medu, id: " + id);
        let target = document.getElementById(id);
        if (target.classList.contains("subContainerOpen")){
            //close
            target.classList.remove("subContainerOpen");
            target.classList.add("subContainerClosed");
        } else {
            //Open
            target.classList.remove("subContainerClosed");
            target.classList.add("subContainerOpen");
        }
    }


    return(
        <div className='SNBContainer'>
            <NavLink className='navLink' activeClassName='activeNavLink' to="/" onClick={closeSNB}>{tt.home[displayLang]}</NavLink>
            <NavLink className='navLink' activeClassName='activeNavLink' to="/aboutus" onClick={closeSNB}>{tt.aboutUs[displayLang]}</NavLink>
            <div className='navLink'>{tt.products[displayLang]}</div>
            <div className='subContainerOpen' id='productsSubMenu'>
                <NavLink className='navLink' activeClassName='activeNavLink' to="/instock" 
                    onClick={closeSNB}>{tt.pMenuIS[displayLang]}</NavLink>
                <NavLink className='navLink' activeClassName='activeNavLink' to="/buildyourown" 
                    onClick={closeSNB}>{tt.pMenuBYO[displayLang]}</NavLink>
                <NavLink className='navLink' id="CommponentsDDContainer" activeClassName='activeNavLink' to="/components"
                    onClick={(e)=>{showHideSubMenu("componentsSubMenu")}}>{tt.pMenuComp[displayLang]}</NavLink>
                <div className='subContainerClosed' id='componentsSubMenu'>
                    <NavLink className='navLink' activeClassName='activeNavLink' to="/components/wood"
                        onClick={closeSNB}>{tt.subPWE[displayLang]}</NavLink>
                    <NavLink className='navLink' activeClassName='activeNavLink' to="/components/pcb"
                        onClick={closeSNB}>{tt.subPPCB[displayLang]}</NavLink>
                    <NavLink className='navLink' activeClassName='activeNavLink' to="/components/plate"
                        onClick={closeSNB}>{tt.subPPlate[displayLang]}</NavLink>
                    <NavLink className='navLink' activeClassName='activeNavLink' to="/components/switches"
                        onClick={closeSNB}>{tt.subPSwitches[displayLang]}</NavLink>
                    <NavLink className='navLink' activeClassName='activeNavLink' to="/components/keycaps"
                        onClick={closeSNB}>{tt.subPKeycaps[displayLang]}</NavLink>
                    <NavLink className='navLink' activeClassName='activeNavLink' to="/components/stabs"
                        onClick={closeSNB}>{tt.subPStabs[displayLang]}</NavLink>
                    <NavLink className='navLink' activeClassName='activeNavLink' to="/components/cable"
                        onClick={closeSNB}>{tt.subPCables[displayLang]}</NavLink>
                </div>
                <NavLink className='navLink' activeClassName='activeNavLink' to="/ourCreations"
                    onClick={closeSNB}>{tt.pMenuOurCreations[displayLang]}</NavLink>
            </div>
        <NavLink className='navLink' activeClassName='activeNavLink' to="/ownerspage"
            onClick={closeSNB}>{tt.ownersPage[displayLang]}</NavLink>
        <NavLink className='navLink' activeClassName='activeNavLink' to="/policies"
            onClick={closeSNB}>{tt.policices[displayLang]}</NavLink>
        </div>
    )
}

export {SideNavBar}
