import React, { useState, useEffect } from 'react';
import './policies.css';
import { policiesSourceTextENG, policiesSourceTextFR } from '../../Translations/ENG';


const PoliciesPage=({textDataArray, displayLang})=>{
    let policiesArray = [];
    displayLang == "ENG"? policiesArray= policiesSourceTextENG.policies : policiesArray= policiesSourceTextFR.policies;
    let tt = textDataArray._policies;
    return(
        <div id='policiesPage'>
            <div className='sectionTitle'>
                <h1>{tt.h1[displayLang]}</h1>
                <h2>{tt.h2[displayLang]}</h2>
            </div>
            <div className='innerText'>
                <p>{tt.p[displayLang]}</p>
            </div>
            <PolicyContent policiesArray={policiesArray}></PolicyContent>
        </div>
    )                     
}


const PolicyContent =({policiesArray})=>{
    // console.log(JSON.stringify(policiesArray));
    return(
        <>
        {policiesArray.map((policy)=>
            <div className='section' id={policy.name}>
            <h1>{policy.name}</h1>
            {policy.text.map((paragraph)=>     
            <>     
                {paragraph.h2 ? (
                    <>
                        <h2>{paragraph.h2}</h2>
                        {paragraph.p.map((innerText)=>(
                           <p>{innerText}</p> 
                        ))}
     
                    </>)
                    :(<>
                        {paragraph.p.map((innerText)=>(
                           <p>{innerText}</p> 
                        ))}
                    
                    </>)}
                
            </>
                )}   
            </div>)}
        </>
    )
}


export{PoliciesPage};
