import React, { useState, useEffect, useContext } from 'react';
import './BYO.css';
import { backendURLContext } from '../../../src/index.js'
import PCB_001_3 from '../../images/PCB_001_3.jpg';
import PCB_002_1 from '../../images/PCB_002_1.jpg';
import PCB_003_1 from '../../images/PCB_003_1.jpg';
import woodEssence_001_1 from '../../images/woodEssence_001_1.jpg';
import woodEssence_002_1 from '../../images/woodEssence_002_1.jpg';
import woodEssence_003_1 from '../../images/woodEssence_003_1.jpg';
import woodEssence_004_1 from '../../images/woodEssence_004_1.jpg';
import woodEssence_005_1 from '../../images/woodEssence_005_1.jpg';
import woodEssence_006_1 from '../../images/woodEssence_006_1.jpg';
import woodEssence_007_1 from '../../images/woodEssence_007_1.jpg';
import woodEssence_008_1 from '../../images/K0001_1.jpg';
import plate_001_3 from '../../images/plate_001_3.jpg';
import plate_002_1 from '../../images/plate_002_1.jpg';
import plate_003_1 from '../../images/plate_003_1.jpg';
import switches_001_1 from '../../images/switches_001_8.jpg';
import switches_002_1 from '../../images/switches_002_1.jpg';
import switches_003_1 from '../../images/switches_003_9.jpg';
import switches_004_1 from '../../images/switches_004_1.jpg';
import switches_005_1 from '../../images/switches_005_1.jpg';
import switches_006_1 from '../../images/switches_006_1.jpg';
import switches_007_1 from '../../images/switches_007_1.jpg';
import cable_001_1 from '../../images/cable_001_1.jpg';
import cable_002_1 from '../../images/cable_002_1.jpg';
import cable_003_1 from '../../images/cable_003_1.jpg';
import cable_004_1 from '../../images/cable_004_1.jpg';
import cable_005_1 from '../../images/cable_005_1.jpg';
import cable_aviator_black from '../../images/cable_aviator_black.jpg';
import cable_aviator_darkRed from '../../images/cable_aviator_darkRed.jpg';
import cable_aviator_lightBlue from '../../images/cable_aviator_lightBlue.jpg';
import cable_aviator_pink from '../../images/cable_aviator_pink.jpg';
import cable_aviator_white from '../../images/cable_aviator_white.jpg';
import stabs_001_1 from '../../images/stabs_001_1.jpg';
import stabs_002_1 from '../../images/stabs_002_1.jpg';
import Lube_factory from '../../images/Lube_factory.jpg';
import Lube_hand from '../../images/Lube_hand.jpg';
import Lube_special from '../../images/Icon_questionMark_2951940_White.png';
import keycaps_001_1 from '../../images/keycaps_001_1.jpg';
import keycaps_002_1 from '../../images/keycaps_002_1.jpg';
import keycaps_003_1 from '../../images/keycaps_003_1.jpg';
import wristrest from '../../images/wristrest.jpg';
import wristAndBox from '../../images/wristAndBox.jpg';
import box from '../../images/box.jpg';





function BYO({updateBuild, createBuild, createCart, getLocalStorageData, setCart, cartUUID,
    animateModal, animateModalLoader, clearModalLoader, textDataArray, displayLang}){
    const urls = useContext(backendURLContext);
    const backendURL = urls.backend;
    const frontendURL = urls.frontend;
    const tt = textDataArray._BYO;

    const[PCBDisplayedImgPath, setPCBDisplayedImgPath] = useState(PCB_001_3);
    const[WoodDisplayedImgPath, setWoodDisplayedImgPath] = useState("../../images/PCB_001_2.jpg");
    const[PlateDisplayedImgPath, setPlateDisplayedImgPath] = useState('../../images/Icon_Plate_White.png');
    const[StabsDisplayedImgPath, setStabsDisplayedImgPath] = useState("../../images/PCB_001_2.jpg");
    const[SwitchesDisplayedImgPath, setSwitchesDisplayedImgPath] = useState("../../images/PCB_001_2.jpg");
    const[LubeDisplayedImgPath, setLubeDisplayedImgPath] = useState("../../images/PCB_001_2.jpg");
    const[KeycapsDisplayedImgPath, setKeycapsDisplayedImgPath] = useState("../../images/PCB_001_2.jpg");
    const[CableDisplayedImgPath, setCableDisplayedImgPath] = useState("../../images/PCB_001_2.jpg");
    const[AccessoriesDisplayedImgPath, setAccessoriesDisplayedImgPath] = useState("../../images/PCB_001_2.jpg");

    useEffect(() => {
        async function checkBuild(){
            let localStorageCart = {}, buildCount = 0, buildIP=false, k;
            //Check if cart exist in local storage, if not call backend and store in local storage AND in state
            let existCart = localStorage.getItem("cart")? true:false;
            console.log("dans use effect parge BYO, exist cart? " + existCart);
    
    
            if (existCart == false){ //call backend to get cart object
                  localStorageCart = await createCart();
                  buildCount = localStorageCart._buildRequest.length;
            } else {
                  localStorageCart = await getLocalStorageData('cart');
                  console.log( "local storage cart: " + localStorageCart, " uuid: " + localStorageCart._UUID);
                  setCart(localStorageCart);
                  buildCount = localStorageCart._buildRequest.length;
            }
    
            console.log("dans use effet page BYO, buildcount: " + buildCount);
            if(buildCount == 0){
                createBuild(localStorageCart._UUID);
            } else{
                //check if there is an ip build
                console.log("dans le selse");
                localStorageCart._buildRequest.map((build)=>{
                    console.log("build status: " + build._status);
                    if (build._status == "Initiated"){
                        buildIP = true;
                        k = build._keyboard._BYOUI;
                        // map to display already choosen visual
                        for (const prop in k){
                            console.log(prop + k[prop]);
                            try{
                                choiceSelection(prop, k[prop].val, k[prop].group, k[prop].targetID, k[prop].detailID, false)
                            }
                            catch(err){
                                console.log("en erreur, prop: " + prop + err);
                            }
                            
                        }
                        //set other property
                        document.getElementById("kbdName").value = k._name.val;
                        document.getElementById("specialRequest").value = k._specialRequests.val;
                        document.getElementById("email").value = k._email.val;



                        
                        // build._keyboard._BYOUI.map((choice)=>{
                            // console.log(build._keyboard._BYOUI[choice]);
                            // choiceSelection(build._keyboard._BYOUI[choice])
                        // })
                    }
                })

                console.log("apres le map, buildIP: " + buildIP);
                if(buildIP == false){
                    console.log("dans buildIP = fakse");
                    createBuild(localStorageCart._UUID);
                }
            }
            //Faudrait aussi que jajuste le visuel des boutons selectionnes, mais pour ca, ca me prend les id des boutons...
        }
        
        checkBuild();
    }, [])

    const setName=(buildName, id)=>{
        console.log('dans setName, buildname: ' + buildName);
        updateBuild("_name", buildName, "", id, "");
    }

    const choiceSelection=(property, newVal, group, targetID, detailID, updateBackEnd)=>{
        let target = document.getElementById(targetID), cl="componentOptionSelected";
        
        console.log("dans choice selection, id: " + targetID + " groupe: " + group + 
        "classlist: " + JSON.stringify(target.classList));

        //Manage frontend

        //Set the choice in the build, using target type and target specific choice
        //Remove selected style from other button in the group, that might have been clicked before
        let targetGroup = document.getElementById(group);
        let fieldsToClear = targetGroup.querySelectorAll(".componentOptionButton");
        fieldsToClear.forEach((field)=>{
            field.classList.remove("componentOptionSelected");
            });
        //Apply selected style
            if(target.classList.contains(cl)){
                target.classList.remove(cl)
            } else {target.classList.add(cl)}

        showHideSelectorDetails(detailID);

        //backend operations
        if(updateBackEnd){
            updateBuild(property, newVal, group, targetID, detailID);
        }
        
    }

    const resetBuild=(showModal)=>{
        let fieldsToClear = document.querySelectorAll(".componentOptionButton");
        // Clear values present before input, so placeholder can show
        fieldsToClear.forEach((field)=>{
        console.log("query selector all, field: " + field);
        field.classList.remove("componentOptionSelected");
        });
        document.querySelector('#kbdName').value='';

        //call backend to reset build
        let updateSucessfull = false;
            fetch(backendURL+"/cart/resetBuild", {
                method: 'POST',
                headers: new Headers({'content-type':'application/json'}),
                body: JSON.stringify({
                    'UUID': cartUUID,
                })
            })  
            .then((resObject)=>{
                if(!resObject.ok){
                    return resObject.json();
                } else {
                    updateSucessfull = true;
                    return resObject.json();
                }
            })
            .then((res)=>{
                localStorage.setItem('cart',JSON.stringify(res));
                setCart(res);
                if (showModal){
                    animateModal(textDataArray._modal.buildReset[displayLang], 2000) 
                }                
            })
    }

    const showHideSelectorDetails=(targetID)=>{
        let target = document.getElementById(targetID), visible="detailsVisible", hidden="detailsHidden";
        let fieldsToClear = document.querySelectorAll(".detailsVisible");
            // clear visible
     
            console.log("dans show hie");
            fieldsToClear.forEach((field)=>{
                console.log("query selector all, field: " + field);
                field.classList.remove(visible);
                field.classList.add(hidden);
            });
            target.classList.add(visible);
            target.classList.remove(hidden)

    }

    const requestQuotation= async ()=>{
        //Check if email is valid
        let email = document.querySelector('#email').value;
        console.log("email:  " + email);
        let validatedEmail = await validateEmail(email);
        if (validatedEmail === null){
            console.log("invalid email");
            animateModal(textDataArray._modal.invalidEmail[displayLang], 2000)
        } else{
            animateModalLoader(textDataArray._modalLoader.byoRequest[displayLang])
            // call backend to request quotation 
            let updateSucessfull = false;
            console.log("dans requestQuotation, cartUUID: " + cartUUID);
            fetch(backendURL+"/cart/requestQutotation", {
                method: 'POST',
                headers: new Headers({'content-type':'application/json'}),
                body: JSON.stringify({
                    'UUID': cartUUID,
                    'customerEmail': email,
                    'lang':displayLang
                })
            })  
            .then((resObject)=>{
                if(!resObject.ok){
                    return resObject.json();
                } else {
                    updateSucessfull = true;
                    return resObject.json();
                }
            })
            .then((res)=>{
                clearModalLoader();
                localStorage.setItem('cart',JSON.stringify(res));
                setCart(res);
                animateModal(textDataArray._modal.byoRequestSent[displayLang], 5000)
                resetBuild(false);
            })
        }
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };


    return(
        <div id='BYOPage'>
            <div className='bgBlurr'>
                <div className='sectionTitle'>
                    <h1>{tt.headerH1[displayLang]}</h1>
                    <h2>{tt.headerH2[displayLang]}</h2>
                    <p>{tt.headerP[displayLang]} </p>
                </div>
                <div className='BYOMainContainer'>
                    {/* <div className='completedKbdImg'>
                        <img src={componentDisplayedImgPath}></img>
                        <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                        while keeping the fundamentals arrow keys</p>
                    </div> */}
                    
                    <div className='BYOselectionContainer'>
                        <div className='componentsSelectorContainer'>
                            <div className='componentsSelector'> {/*Build name*/}
                                <div className='componentName'>
                                    {tt.sectionTitleName[displayLang]}
                                </div>
                                <div className='componentOptions'>
                                    <input type='text'  id="kbdName"
                                        placeholder={tt.sectionTitleName[displayLang]}
                                        onChange={(e)=>{setName(e.target.value, 'kbdName')}}
                                    />
                                </div>
                            </div>
                            <div className='componentsSelector' > {/*Form factor*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Keyboard_White.png')} alt="Form Factor"/>
                                    {tt.sectionTitleFormFactor[displayLang]}
                                </div>
                                <div className='componentOptions' id="formFactorOptionsContainer">
                                    <button className='componentOptionButton' id='pcb_65norgb'
                                        onMouseEnter={()=>{setPCBDisplayedImgPath(PCB_001_3)}}
                                        onClick={()=>{choiceSelection("_formFactor",tt.optionFF65NoRgb[displayLang],"formFactorOptionsContainer","pcb_65norgb","formFactorCSContainer", true)}}>
                                        {tt.optionFF65NoRgb[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='pcb_65rgb'
                                        onMouseEnter={()=>{setPCBDisplayedImgPath(PCB_002_1)}}
                                        onClick={()=>{choiceSelection("_formFactor",tt.optionFF65Rgb[displayLang],"formFactorOptionsContainer","pcb_65rgb","formFactorCSContainer", true)}}>
                                        {tt.optionFF65Rgb[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='pcb_Numpad'
                                        onMouseEnter={()=>{setPCBDisplayedImgPath(PCB_003_1)}}
                                        onClick={()=>{choiceSelection("_formFactor",tt.optionFFnumpad[displayLang],"formFactorOptionsContainer","pcb_Numpad","formFactorCSContainer", true)}}>
                                        {tt.optionFFnumpad[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id='formFactorCSContainer'>
                                    {/* <img className="bigImg" src={require(PCBDisplayedImgPath)}></img> */}
                                    {/* <img className="bigImg" src= {"../../images/" + PCBDisplayedImgPath}></img> */}
                                    <img className="bigImg" src= {PCBDisplayedImgPath}></img>
                                    
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>
                            <div className='componentsSelector'> {/*Wood back plate*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Wood_White.png')} alt="Wood"/>
                                    {tt.sectionTitleWE[displayLang]}
                                </div>
                                <div className='componentOptions' id="woodOptionsContainer" style={{justifyContent:'flex-start'}}>
                                    <button className='componentOptionButton' id='wood_walnut'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_008_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWEWalnut[displayLang],"woodOptionsContainer","wood_walnut","woodCSContainer", true)}}>
                                        {tt.optionWEWalnut[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='wood_Padouk'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_007_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWEPadouk[displayLang],"woodOptionsContainer","wood_Padouk","woodCSContainer", true)}}>
                                        {tt.optionWEPadouk[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='wood_Merisier'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_004_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWEMMerisier[displayLang],"woodOptionsContainer","wood_Merisier","woodCSContainer", true)}}>
                                        {tt.optionWEMMerisier[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='wood_Amarante'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_001_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWEAmarante[displayLang],"woodOptionsContainer","wood_Amarante","woodCSContainer", true)}}>
                                        {tt.optionWEAmarante[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='wood_AmbrosiaPlain'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_005_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWEAmbrosia[displayLang],"woodOptionsContainer","wood_AmbrosiaPlain","woodCSContainer", true)}}>
                                        {tt.optionWEAmbrosia[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='wood_Sapele'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_002_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWESapele[displayLang],"woodOptionsContainer","wood_Sapele","woodCSContainer", true)}}>
                                        {tt.optionWESapele[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='wood_Mahogany'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_006_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWEMahogany[displayLang],"woodOptionsContainer","wood_Mahogany","woodCSContainer", true)}}>
                                        {tt.optionWEMahogany[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='wood_Zebrawood'
                                        onMouseEnter={()=>{setWoodDisplayedImgPath(woodEssence_003_1)}}
                                        onClick={()=>{choiceSelection("_woodEssence",tt.optionWEMZebra[displayLang],"woodOptionsContainer","wood_Zebrawood","woodCSContainer", true)}}>
                                        {tt.optionWEMZebra[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="woodCSContainer">
                                    <img className="bigImg" src={WoodDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>
                            <div className='componentsSelector'> {/*Plate*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Plate_White.png')} alt="Plate"/>
                                    {tt.sectionTitlePlate[displayLang]}
                                </div>
                                <div className='componentOptions' id="plateOptionsContainer">
                                    <button className='componentOptionButton' id='plate_Aluminium'
                                        onMouseEnter={()=>{setPlateDisplayedImgPath(plate_001_3)}}
                                        onClick={()=>{choiceSelection("_plate",tt.optionPlateAlu[displayLang],"plateOptionsContainer","plate_Aluminium","plateCSContainer", true)}}>
                                        {tt.optionPlateAlu[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='plate_FR4White'
                                        onMouseEnter={()=>{setPlateDisplayedImgPath(plate_002_1)}}
                                        onClick={()=>{choiceSelection("_plate",tt.optionPlateFR4White[displayLang],"plateOptionsContainer","plate_FR4White","plateCSContainer", true)}}>
                                        {tt.optionPlateFR4White[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='plate_FR4Black'
                                        onMouseEnter={()=>{setPlateDisplayedImgPath(plate_003_1)}}
                                        onClick={()=>{choiceSelection("_plate",tt.optionPlateFR4Black[displayLang],"plateOptionsContainer","plate_FR4Black","plateCSContainer", true)}}>
                                       {tt.optionPlateFR4Black[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="plateCSContainer">
                                    <img className="bigImg" src={PlateDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>

                            <div className='componentsSelector'> {/*Stabs*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Stabs_White_4965548.png')} alt="Stabs"/>
                                    {tt.sectionTitleStabs[displayLang]}
                                </div>
                                <div className='componentOptions' id="stabsOptionsContainer">
                                    <button className='componentOptionButton' id='stabs_DurockSmokey'
                                        onMouseEnter={()=>{setStabsDisplayedImgPath(stabs_001_1)}}
                                        onClick={()=>{choiceSelection("_stabs",tt.optionStabsDrSISmokey[displayLang],"stabsOptionsContainer","stabs_DurockSmokey","stabsCSContainer", true)}}>
                                        {tt.optionStabsDrSISmokey[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='stabs_DurockTransparent'
                                        onMouseEnter={()=>{setStabsDisplayedImgPath(stabs_002_1)}}
                                        onClick={()=>{choiceSelection("_stabs",tt.optionStabsDrSITransparent[displayLang],"stabsOptionsContainer","stabs_DurockTransparent","stabsCSContainer", true)}}>
                                       {tt.optionStabsDrSITransparent[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="stabsCSContainer">
                                    <img className="bigImg" src={StabsDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>

                            <div className='componentsSelector'> {/*Switches*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Switch_White.png')} alt="Switches"/>
                                    {tt.sectionTitleSwitches[displayLang]}
                                </div>
                                <div className='componentOptions fs' id="switchesOptionsContainer">
                                    <button className='componentOptionButton' id='switches_GateronGPro3Yellow'
                                        onMouseEnter={()=>{setSwitchesDisplayedImgPath(switches_005_1)}}
                                        onClick={()=>{choiceSelection("_switches",tt.optionSwGateronGPro3Yellow[displayLang],"switchesOptionsContainer","switches_GateronGPro3Yellow","switchesCSContainer", true)}}>
                                        {tt.optionSwGateronGPro3Yellow[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='switches_GateronGPro3Blue'
                                        onMouseEnter={()=>{setSwitchesDisplayedImgPath(switches_001_1)}}
                                        onClick={()=>{choiceSelection("_switches",tt.optionSwGateronGPro3Blue[displayLang],"switchesOptionsContainer","switches_GateronGPro3Blue","switchesCSContainer", true)}}>
                                        {tt.optionSwGateronGPro3Blue[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='switches_GateronGPro3Brown'
                                        onMouseEnter={()=>{setSwitchesDisplayedImgPath(switches_002_1)}}
                                        onClick={()=>{choiceSelection("_switches",tt.optionSwGateronGPro3Brown[displayLang],"switchesOptionsContainer","switches_GateronGPro3Brown","switchesCSContainer", true)}}>
                                        {tt.optionSwGateronGPro3Brown[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='switches_GateronGPro3Black'
                                        onMouseEnter={()=>{setSwitchesDisplayedImgPath(switches_003_1)}}
                                        onClick={()=>{choiceSelection("_switches",tt.optionSwGateronGPro3Black[displayLang],"switchesOptionsContainer","switches_GateronGPro3Black","switchesCSContainer", true)}}>
                                        {tt.optionSwGateronGPro3Black[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='switches_GateronGPro3Red'
                                        onMouseEnter={()=>{setSwitchesDisplayedImgPath(switches_004_1)}}
                                        onClick={()=>{choiceSelection("_switches",tt.optionSwGateronGPro3Red[displayLang],"switchesOptionsContainer","switches_GateronGPro3Red","switchesCSContainer", true)}}>
                                        {tt.optionSwGateronGPro3Red[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='switches_GateronGPro3White'
                                        onMouseEnter={()=>{setSwitchesDisplayedImgPath(switches_006_1)}}
                                        onClick={()=>{choiceSelection("_switches",tt.optionSwGateronGPro3White[displayLang],"switchesOptionsContainer","switches_GateronGPro3White","switchesCSContainer", true)}}>
                                        {tt.optionSwGateronGPro3White[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='switches_GateronGPro3Silver'
                                        onMouseEnter={()=>{setSwitchesDisplayedImgPath(switches_007_1)}}
                                        onClick={()=>{choiceSelection("_switches",tt.optionSwGateronGPro3Silver[displayLang],"switchesOptionsContainer","switches_GateronGPro3Silver","switchesCSContainer", true)}}>
                                        {tt.optionSwGateronGPro3Silver[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="switchesCSContainer">
                                    <img className="bigImg" src={SwitchesDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>

                            <div className='componentsSelector'> {/*Lube*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Lube_White_505143.png')} alt="Lube"/>
                                    {tt.sectionTitleLube[displayLang]}
                                </div>
                                <div className='componentOptions' id="lubeOptionsContainer">
                                    <button className='componentOptionButton' id='lube_FactoryLube'
                                        onMouseEnter={()=>{setLubeDisplayedImgPath(Lube_factory)}}
                                        onClick={()=>{choiceSelection("_lube",tt.optionLubeFactory[displayLang],"lubeOptionsContainer","lube_FactoryLube","lubeCSContainer", true)}}>
                                        {tt.optionLubeFactory[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='lube_Crytox205G0'
                                        onMouseEnter={()=>{setLubeDisplayedImgPath(Lube_hand)}}
                                        onClick={()=>{choiceSelection("_lube",tt.optionLube205[displayLang],"lubeOptionsContainer","lube_Crytox205G0","lubeCSContainer", true)}}>
                                       {tt.optionLube205[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='lube_HeavyLube'
                                        onMouseEnter={()=>{setLubeDisplayedImgPath(Lube_special)}}
                                        onClick={()=>{choiceSelection("_lube",tt.optionLubeHeavy[displayLang],"lubeOptionsContainer","lube_HeavyLube","lubeCSContainer", true)}}>
                                       {tt.optionLubeHeavy[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="lubeCSContainer">
                                    <img className="bigImg" src={LubeDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>

                            <div className='componentsSelector'> {/*Keycaps*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Keycap_White.png')} alt="Keycaps"/>
                                    Keycaps
                                </div>
                                <div className='componentOptions'  id="keycapsOptionsContainer" style={{justifyContent:'flex-start'}}>
                                    <button className='componentOptionButton' id='OEM_Double_Shot_PBT_keycaps_Black'
                                        onMouseEnter={()=>{setKeycapsDisplayedImgPath(keycaps_001_1)}}
                                        onClick={()=>{choiceSelection("_keycaps","OEM Double Shot PBT keycaps, Black","keycapsOptionsContainer","OEM_Double_Shot_PBT_keycaps_Black","keycapsCSContainer", true)}}>
                                        OEM Double Shot PBT keycaps, Black
                                    </button>
                                    <button className='componentOptionButton' id='OEM_Pudding_keycaps_White'
                                        onMouseEnter={()=>{setKeycapsDisplayedImgPath(keycaps_002_1)}}
                                        onClick={()=>{choiceSelection("_keycaps","OEM Pudding keycaps, White","keycapsOptionsContainer","OEM_Pudding_keycaps_White","keycapsCSContainer", true)}}>
                                        OEM Pudding keycaps, White
                                    </button>
                                    <button className='componentOptionButton' id='OEM_Pudding_keycaps_Blue'
                                        onMouseEnter={()=>{setKeycapsDisplayedImgPath(keycaps_003_1)}}
                                        onClick={()=>{choiceSelection("_keycaps","OEM Pudding keycaps, Blue","keycapsOptionsContainer","OEM_Pudding_keycaps_Blue","keycapsCSContainer", true)}}>
                                        OEM Pudding keycaps, Blue
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="keycapsCSContainer">
                                    <img className="bigImg" src={KeycapsDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>

                            <div className='componentsSelector'> {/*USB cable*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_USB_White.png')} alt="Cable"/>
                                    {tt.sectionTitleCable[displayLang]}
                                </div>
                                <div className='componentOptions fs' id="cableOptionsContainer">
                                    <button className='componentOptionButton' id='cable_BraidedRed'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_003_1)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableBraidedRed[displayLang],"cableOptionsContainer","cable_BraidedRed","cablesCSContainer", true)}}>
                                        {tt.optionCableBraidedRed[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_BraidedBlack'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_001_1)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableBraidedBlack[displayLang],"cableOptionsContainer","cable_BraidedBlack","cablesCSContainer", true)}}>
                                        {tt.optionCableBraidedBlack[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_BraidedGreen'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_002_1)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableBraidedGreen[displayLang],"cableOptionsContainer","cable_BraidedGreen","cablesCSContainer", true)}}>
                                        {tt.optionCableBraidedGreen[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_SiliconeWhite'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_005_1)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableSiliconWhite[displayLang],"cableOptionsContainer","cable_SiliconeWhite","cablesCSContainer", true)}}>
                                        {tt.optionCableSiliconWhite[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_SiliconeOrange'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_004_1)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableSiliconOrange[displayLang],"cableOptionsContainer","cable_SiliconeOrange","cablesCSContainer", true)}}>
                                        {tt.optionCableSiliconOrange[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_AviatorDarkRed'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_aviator_darkRed)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableAviatorDRed[displayLang],"cableOptionsContainer","cable_AviatorDarkRed","cablesCSContainer", true)}}>
                                        {tt.optionCableAviatorDRed[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_AviatorWhite'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_aviator_white)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableAviatorWhite[displayLang],"cableOptionsContainer","cable_AviatorWhite","cablesCSContainer", true)}}>
                                        {tt.optionCableAviatorWhite[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_AviatorBlack'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_aviator_black)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableAviatorBlack[displayLang],"cableOptionsContainer","cable_AviatorBlack","cablesCSContainer", true)}}>
                                        {tt.optionCableAviatorBlack[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_AviatorPink'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_aviator_pink)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableAviatorPink[displayLang],"cableOptionsContainer","cable_AviatorPink","cablesCSContainer", true)}}>
                                        {tt.optionCableAviatorPink[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='cable_AviatorLight Blue'
                                        onMouseEnter={()=>{setCableDisplayedImgPath(cable_aviator_lightBlue)}}
                                        onClick={()=>{choiceSelection("_cable",tt.optionCableAviatorLightBlue[displayLang],"cableOptionsContainer","cable_AviatorLight Blue","cablesCSContainer", true)}}>
                                        {tt.optionCableAviatorLightBlue[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="cablesCSContainer">
                                    <img className="bigImg" src={CableDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>

                            <div className='componentsSelector'> {/*Accessories*/}
                                <div className='componentName'>
                                    <img src={require('../../images/Icon_Accessories_White_6476442.png')} alt="Accessories"/>
                                    {tt.sectionTitleAccessories[displayLang]}
                                </div>
                                <div className='componentOptions' id="accessoriesptionsContainer">
                                    <button className='componentOptionButton' id='Accessories_WristRest'
                                        onMouseEnter={()=>{setAccessoriesDisplayedImgPath(wristrest)}}
                                        onClick={()=>{choiceSelection("_accessories",tt.optionAccessoriesWR[displayLang],"accessoriesptionsContainer","Accessories_WristRest","accessoriesCSContainer", true)}}>
                                        {tt.optionAccessoriesWR[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='Accessories_CoverBox'
                                        onMouseEnter={()=>{setAccessoriesDisplayedImgPath(box)}}
                                        onClick={()=>{choiceSelection("_accessories",tt.optionAccessoriesBox[displayLang],"accessoriesptionsContainer","Accessories_CoverBox","accessoriesCSContainer", true)}}>
                                        {tt.optionAccessoriesBox[displayLang]}
                                    </button>
                                    <button className='componentOptionButton' id='Accessories_WristAndBox'
                                        onMouseEnter={()=>{setAccessoriesDisplayedImgPath(wristAndBox)}}
                                        onClick={()=>{choiceSelection("_accessories",tt.optionAccessoriesWRBox[displayLang],"accessoriesptionsContainer","Accessories_WristAndBox","accessoriesCSContainer", true)}}>
                                        {tt.optionAccessoriesWRBox[displayLang]}
                                    </button>
                                </div>
                                <div className='transitionTarget detailsHidden' id="accessoriesCSContainer">
                                    <img className="bigImg" src={AccessoriesDisplayedImgPath}></img>
                                    {/* <p className='choiceText'>Good choice. Going with a 65% pcb ensure compactness and portability
                                    while keeping the fundamentals arrow keys</p> */}
                                </div>
                            </div>

                            <div className='componentsSelector'> {/*Special Request*/}
                                <div className='componentName'>
                                    {tt.specialRequestTitle[displayLang]}
                                </div>
                                <div className='componentOptions'>
                                    <textarea
                                        id="specialRequest"
                                        placeholder={tt.specialRequestPlaceholder[displayLang]}
                                        onChange={(e)=>{updateBuild("_specialRequests",e.target.value, '',"specialRequest","", true)}}
                                    />
                                </div>
                            </div>
                            <div className='componentsSelector'> {/*Email*/}
                                <div className='componentName'>
                                    {tt.emailTitle[displayLang]}
                                </div>
                                <div className='componentOptions'>
                                    <input type='email' pattern=".+@example\.com" id="email"
                                        placeholder={tt.emailPlaceholder[displayLang]}
                                        onChange={(e)=>{updateBuild("_email",e.target.value, '',"email","", true)}}
                                    />
                                </div>
                            </div>


                            <div className='componentsSelector noBG' id='componentOptionsFinalButton'>
                                <div className='componentOptions'>
                                    <button className='componentOptionButton' onClick={()=>{resetBuild(true)}}>{tt.resetBtn[displayLang]}</button>
                                    <button className='componentOptionButton'
                                        onClick={()=>{requestQuotation()}}>{tt.requestBuildBtn[displayLang]}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export{BYO};
