import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './cart.css';
import { Return } from '../../Stripe/Stripe';

function CartReturn({cartUUID, setCart, textDataArray, displayLang}) {
    const tt = textDataArray._cartReturn;
    return(
        <div id='cartPageContainer' className='cartMIR'>
            <div >
                <h1>{tt.headerH1[displayLang]}</h1>
                <Return 
                    cartUUID={cartUUID} 
                    setCart={setCart}
                    textDataArray={textDataArray}
                    displayLang={displayLang}
                    />
            </div>
        </div>    
    )
}

export {CartReturn}