let policiesSourceTextENG = {
    policies:[
        {name: "Terms of services",
        text: [{
            h2: "Website and service usage",
            p: [
                "Welcome to Hide Nothing Keyboard's website. Please make sure to read and understand theses Terms of services. This website is owned and operated by Hide Nothing Keyboard. Throughout our site, usage of \"we\", \"us\" and  \"our\" refer to Hide Nothing Keyboard. This website and any of our services all avaiable upon your acceptance of all terms, conditions and policies stated here.",
                "Any and all content, data, graphics, photographs, images, audio, video, software, trademarks, service marks, trade names, and other information (collectively, the “Content”) contained in this Website are proprietary to Hide Nothing Keyboard. The Content is protected by Canadian and international copyright and trademark laws.",
                "By visiting any part of our site and/or making a purchase, you engage in our \"Service\" and agree to bound by the present terms and conditions, including all policies presented anywhere on our website.",
                "We reserve the right to update, change or replace any part of theses Terms of services by updating our website, with no obligation to inform you of theses changes. It is your responsibility to check the terms of services before using our services. Your continuous usage or our website and/or services represent your consent to theses changes",
                "You hereby explicitely agree to use our website in a way that (i) does not represent any unlawful purpose, (ii) violate any international, federal, proviincial, local on any other law or rules, (iii) may harm or injure yourself or anyone else, (iv) violate our or anyone else intellectual property, (v) provide false or misleading information, (vi) cause any dommage to our website or users of our website",
                "We reserve the right to refuse you the access to our website and/or services, at any time, at our own discretion. Hide Nothing keybaord is not bound to provide any explanation or prior notice to this exclusion",
                "You use this website at your own risk. Although we deploy all possible effort to ensure the accuracy of the information presented in our webiste, we do not guarantee, represent or warrant exactness of the content or the description of our products. This website may containt errors, inaccuracies, incomplete or out of date information or bug."
            ]
        },
        {
            h2: "Limitation of liability",
            p: [
                "You expressly agree that your use of, or inability to use, the Service is at your sole risk. You hereby waive all remedies, warranties, guarantees or liabilites, arising from law or otherwise. The Service and all products and Services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.",
                "In no case can Hide Noting Keyboard, our directors, managers, or any other employees be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of our services or any products",
                "We hereby excludes all liability with respect to the transmission of viruses or bugs. This entire limitation shall apply even if Hide Nothing Keyboard, its directors, managers, or any other employees were informed of the possible occurrence of the abovementioned damages. Our maximum liability shall in no event exceed the amount paid for any purchase. This limitation is an essential element of the agreement between Hide Nothing Keyboard and you",
                "If any jurisdiction does not allow exclusion of liability, our liability shall be limited the maximum extent permitted by law"
            ]
        },
        {
            h2: "Idemnification",
            p: [
                "You agree to indemnify, defend and hold harmless Hide Nothing Keyboard, it's directors, manager or any other employee or agent, harmless from any claim or demand, including reasonable attorneys fees, resulting of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party."
            ]
        },
        {
            h2: "Order and paiement",
            p: [
                "We reserve the right to refuse any order or quote requested by you withouth limitation of reason. We reserve the right to stop providing any product in our store, and we do not guaranty the availability of any product, shown or not in our website. If such order or request is refused, you will be noticed by email. If an order is cancelled or refused after paiement, you will be refund the initial amount paid.",
                "Approval of paiement is handle by Stripe, which is a third party and is out of our control. We are not responsible for any refusal of any paiement method provided on our website",
                "By making purchase with us, you agree not to resale any product, shall it be complete or any part of it."
            ]
        },
        {
            h2: "Jurisdiction",
            p: [
                "This website, usage of this website and any disputes related to product purchased from this website are governed and construed by the laws applicable to the Province of Quebec (Canada), without regard to conflict of law rules. Both Hide Nothing Keyboard and you agree to the exclusive jurisdiction of the Province of Quebec (Canada).",
                "This Agreement, including the policies present in this webpage, shall constitute the entire agreement between the parties and there are no other written or verbal agreements or representations relating to the subject matters described herein.",
                "Should any provision of this Terms of Use be deemed invalid or unenforceable, those provisions shall be severed from the Agreement, but shall not affect the validity of the other provisions."
            ]
        }
    ]},
        {name: "Warranty policy",
        text: [{
            p: [
                "By accepting our terms of services, you acknowledge that your understand and accept the following:",
                "All of our keyboards are hand made with the best quality of wood. Everywood plank is different and every keyboard will be different as we work them by hand. If you order a custom build keyboard, you understand that we can not predict the exact color or aspect of the backplate, as it will vary with every piece of hardwood. You also aknowlede that all of our keyboard are perfectly imperfect, in that they are not CNC machined, and can present differents sanding angles or edge finish. This is what makes thems unique. A minor variation in color, tone, wood grain, texture or shape will not be considered as a defect.",
                "All of our keyboard and components are covered with a one year limited warranty from the date of shipment. Whis warranty covers defect of workmanship or materials under a normal usage.",
                "A valid proof of purchase will be required before obtaining warranty coverage.",
                "To resolve warranty claims, we will first work with you to identify the source of the issue, and confirm that it is not related to misuse or abuse of your keyboard. Our first efforts will then be directed at solving the issue with your actual keyboard, either by repairing or sending replacement parts. If it's a firmware issue, you might be asked to follow differents steps, including flashing a new firmware on your keyboard. If we can not fix the problem and your keyboard is still withing the warranty period, we'll replace it.",
                "If any issue arise outside of the warranty period, we encourage you to get in touch with us, and we'll do our best to help. Each situation will be examined in a case-by-case manner, although resolution is not guaranteed.",
		 "Customer is assuming all fees and responsibility for return shipment.",
                <a href="mailto:info@hnkbd.ca"> Should you have any question, warranty issue or complaints about your keyboard, please contact us at info@hnkbd.ca.</a>,
            ]
        }]},
        {name: "Cancellation policy",
        text: [{
            p: [
                "You can ask for a cancellation of your order at any time before your products are shipped. For in stock product, you are eligible to a no-questions asked full refund up to the date your order has been transfert to the carrier. For custom build, we can not cancel or refund your order if we have begun sourcing components or building your keyboard.", 
                "Cancellation and refund fees may apply from the payment processor. The client is responsible for theses fees, and they will be deducted from the return amount."
            ]
        }]},
        {name: "Return policy",
        text: [{
            p: [
                "We offer a 30 days return policy for In Stock keyboards and other components. Unfortunately, we can not accept return for custom built keyboard. Please contact us and let us do our best to work it out to your satisfaction.", 
                "To be eligible for a return, the item must be in it's original condition and returned in it's original packaging.",
                "Customer is assuming all fees and responsibility for return shipment.",
                <a href="mailto:info@hnkbd.ca">To apply for a return, please email us at info@hnkbd.ca with the following information</a>,
                <ul>
                    <li>Your name and email</li>
                    <li>The item you wish to return, with the order number</li>
                    <li>Reason for return</li>
                </ul>
            ]
        }]},
        {name: "Refund policy",
        text: [{
            p: [
                "Uppon reciveing and inspect your return, we will confirm by email the approval or rejection of the refund (see warranty and return policies for details).",
                "Refund will be issue from our end within 5 business days. Refund does not cover shipping cost, or any other duties, import taxes, VAT and shipping services related fees."
        ]
        }]},
        {name: "Shipping and Delivery policy",
        text: [
            {
            p:[
                "We strive to get your keyboard to you as soon as possible. For in stock keyboards or stand alone components, we will process and ship your order within 5 business days. For custom made keyboard, if you built your keyboard from parts that we already have in stock, you can expect a delay of 2 to 4 weeks for us to carefully build your unique piece. The delay will likely be closer to the longer end of the spectrum if you asked for lubed switches.. If you placed a special request during the build, the delay will be longer as we have to work with differents supply to get the exact components that fits your need. Custom order of that sorts are usually build in a window of 4 to 6 weeks, but time may differ, depending on the request.",
				"The shipping speed and shipping cost will depend on your destination and the shipping method chosen during the checkout process.",
				"To ensure smooth processing of your order, please make sure to double check your address. Should a parcel be return to sender, we will contact you to get the right information. You will be responsible for paiement of reshipment. Hide Nothing Keyboard will not assume any cost related to erroneous or incorrect shipping information.",
                "If you ordered multiples items, containing custom build keyboard, we will bundle everything in a single shipment, when all item will be ready."
            ]
            },
            {
            h2: "International orders",
            p: [
                "We ship from Québec, Canada. If you are an international customer, you hereby agree that you have the responsibility to be aware of the customs and importations laws for your country. All duties, taxes, VAT, and any shipping services related fees are assumed by the client. HNK is not responsible for the payment of these fees. If, for any reasons, the shipment incure additional shipping charges (for exemple rejection by customs, wrong delivery address, return back to the sender), the additionnal fees will have to be covered by the buyer.",
                "We use Stripe as a payment processing agent, allowing for some currency conversion, but we can not assure conversion to every currency. Hide Nothing Keyboard is not responsible for any conversion fees."
             ]
            },
            {
            h2: "Lost or undelivered packages",
            p: [
                "Should any issue arise after we've transfert the package to carrier, the customer is responsible for resolving the issue with the carrier. Hide Nothing Keyboard is not responsible for lost or undelivered packages. ",
                "You can always contact us at info@hnkbd.ca for information on your order. "
                ]
            }
        ]},
        {name: "Privacy policy",
        text: [
            {p:[
                "This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase on our website hnkbd.ca. This policy applies to Hide Nothing Keyboard's own Websites. Products and services of third-parties or some affiliates are subject to their own separate privacy policies."
            ]},
            {
            h2:"Personnal and usage information",
            p:[
                "When you visit our website, we automatically collect certain information about your device. These may include data about your browser, IP address, time zone, and cookies. We also might collect information about your browsing experience, including the pages or products that you viewed, time spent on each page, what websites or search terms referred you to the Site, and information about how you interact with the Site.",
                "We use different technologies to gather information, namely Cookies and Log files. You can find more information about cookies by visiting http://www.allaboutcookies.org"
            ]},
            {
                h2:"Purchasing information",
                p:[
                    "We use Stripe as a paiement processing third party, and thus we encourage you to consult their privacy policy. https://stripe.com/en-ca/privacy. Hide nothing keyboard do not collect any information about your credit card. They are sent directly to Stripe, in compliance with the PCIe regulation. We do, however, collect information required for handling and shipping your order, such as:",
                    <ul>
                        <li>Your email</li>
                        <li>Your physical addresse</li>
                        <li>Your phone number</li>
                    </ul>
                ]},
            {
            h2:"Usage of personal information",
            p:[
                "We use the information we collect to provide our services to you, which include fulfilling your order, and to communicate with you if we have details to confirm. We also use personnal data for customer support if required. If you made a special request for a custom build, we may contact you by email to obtain different confirmation.",
                "We also use browsing and device information for fraud prevention, technical troubleshooting, and mainly to optimize your browsing experience (for exemple by getting insight of our customer's habit via Google analytics)",
                "We might also use the personnal and usage information collected to:",
                <ul>
                    <li>Oblige to a legal requirement</li>
                    <li>Protect or defend our rights</li>
                    <li>Prevent or pursue illegal usage of our services</li>
                    <li>Protect security of any users of our services</li>
                    <li>Protect Hide Nothing Keyboard from legal liability</li>
                </ul>

            ]},
            {
            h2:"Third party pricacy policy",
            p:[
                "If you want more information on our third party privacy policy, we invite you to consult these links",
                <a href="https://policies.google.com/privacy" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy</a>,
                <a href="https://stripe.com/en-ca/privacy" target="_blank"
                rel="noopener noreferrer">https://stripe.com/en-ca/privacy</a>,
            ]}
        ]}
    ]
}

let policiesSourceTextFR = {policies:[
    {name: "Conditions d'utilisation",
    text: [{
        h2: "Utilisiation de ce site web et de nos services",
        p: [
            "Bienvenue sur le site de Hide Nothing Keyboard. Assurez-vous de lire et comprendre ces conditions d'utilisation. Ce site Web est la propriété de Hide Nothing Keyboard et est exploité par cette entreprise. L'utilisation des termes « nous », « notre » et « nos » fait référence à Hide Nothing Keyboard. Ce site Web et tous nos services sont accessibles uniquement si vous acceptez les conditions générales et les politiques énoncées dans le présent document.",
            "Tout contenu, données, graphiques, photographies, images, audio, vidéo, logiciels, marques de commerce, marques de service, noms commerciaux et autres renseignements (collectivement, le « Contenu ») contenus dans ce site Web sont la propriété exclusive de Hide Nothing Keyboard. Le contenu est protégé par les lois canadiennes et internationales sur le droit d'auteur et les marques de commerce.",
            "En visitant une partie de notre site et/ou en effectuant un achat, vous engagez dans notre 'Service' et acceptez d'être lié par les présentes conditions générales, y compris toutes les politiques présentées n'importe où sur notre site." ,
            "Nous nous réservons le droit de mettre à jour, modifier ou remplacer, en tout ou en partie, les présentes conditions d'utilisation en mettant à jour notre site Web, sans aucune obligation de vous informer de ces changements. Il est de votre responsabilité de vérifier les conditions d'utilisation des services avant de les utiliser. Votre utilisation continue ou notre site web et/ou nos services représentent votre consentement à ces conditions et/ou changements",
            "Vous acceptez expressément d'utiliser notre site Web de manière à (i) ne représenter aucun but illégal, (ii) violer toute loi ou règle internationale, fédérale, provinciale ou locale, (iii) peut vous nuire ou blesser quelqu'un d'autre, (iv) violer notre propriété intellectuelle ou celle de toute autre personne, (v) fournir des informations fausses ou trompeuses, (vi) causer un dommage à notre site Web ou aux utilisateurs de notre site Web",
            "Nous nous réservons le droit de vous refuser l'accès à notre site web et/ou nos services, à tout moment, à notre seule discrétion. Hide Nothing Keyboard n'est pas tenu de fournir une explication ou un préavis à cette exclusion",
            "Vous utilisez ce site à vos propres risques. Bien que nous déployions tous les efforts possibles pour assurer l'exactitude des informations présentées dans notre site, nous ne garantissons pas l'exactitude du contenu ou de la description de nos produits. Ce site web peut contenir des erreurs, des inexactitudes, des renseignements incomplets ou périmés ou des bogues."
        ]
    },
    {
        h2: "Limitation de responsabilité",
        p: [
            "Vous acceptez expressément que votre utilisation ou incapacité à utiliser le service est à vos risques et périls. Vous renoncez par les présentes à tous recours, garanties ou obligations, découlant de la loi ou autrement. Le Service et tous les produits et services qui vous sont fournis par l'intermédiaire du Service sont (sauf mention expresse de notre part) fournis 'tels quels' et 'disponibles' pour votre utilisation, sans aucune déclaration, garantie ou condition de quelque nature que ce soit, expresse ou implicite, y compris toutes les garanties ou conditions implicites de qualité marchande, d'aptitude à un usage particulier, de durabilité, et de non-contrefaçon." ,
            "En aucun cas, Hide Noting Keyboard, nos administrateurs, gestionnaires ou tout autre employé ne peuvent être tenus responsables de toute blessure, perte, réclamation ou de tout dommage direct, indirect, accessoire, punitif, spécial ou consécutif de quelque nature que ce soit, y compris, sans limitation, des pertes de profits ou de revenus, perte de données, coûts de remplacement ou tout dommage similaire, qu'il soit basé sur un contrat, un délit (y compris la négligence), une responsabilité stricte ou autre, découlant de votre utilisation de l'un de nos services ou de nos produits",
            "Nous déclinons toute responsabilité en ce qui concerne la transmission de virus ou de bogues. Cette limitation s'applique même si Hide Nothing Keyboard, ses administrateurs, gestionnaires ou tout autre employé ont été informés de l'éventualité possible des dommages susmentionnés. Notre responsabilité maximale ne dépassera en aucun cas le montant payé pour tout achat. Cette limitation est un élément essentiel de l'accord entre Hide Nothing Keyboard et vous.",
     "Si une juridiction ne permet pas l'exclusion de responsabilité, notre responsabilité sera limitée à la limite maximale autorisée par la loi"
        ]
    },
    {
        h2: "Indemnisation",
        p: [
            "Vous acceptez d'indemniser, de défendre et de tenir indemne Hide Nothing Keyboard, ses administrateurs, son gestionnaire ou tout autre employé ou agent, à l'abri de toute réclamation ou demande, y compris les honoraires raisonnables des avocats, résultant de votre violation des présentes Conditions d'utilisation ou des documents qu'elles contiennent par renvoi, ou de votre violation de la loi ou des droits d'un tiers."
        ]
    },
    {
        h2: "Commande et paiement",
        p: [
            "Nous nous réservons le droit de refuser toute commande ou soumission demandé par vous sans limitation de raison. Nous nous réservons le droit de cesser de fournir tout produit dans notre boutique en ligne, et nous ne garantissons pas la disponibilité d'un produit, affiché ou non sur notre site. Si une telle commande ou demande est refusée, vous serez averti par courriel. Si une commande est annulée ou refusée après le paiement, vous serez remboursé du montant initial payé.",
            "L'approbation des paiements est gérée par Stripe, qui est un tiers et qui échappe à notre contrôle. Nous ne sommes pas responsables du refus de toute méthode de paiement fournie sur notre site",
            "En effectuant un achat avec nous, vous acceptez de ne pas revendre tout produit, qu'il soit complet ou qu'il en soit un composant."
        ]
    },
    {
        h2: "Juridiction",
        p: [
            "Ce site Web, l'utilisation de ce site Web et tout litige lié aux produits achetés sur ce site Web sont régis et interprétés par les lois applicables à la province de Québec (Canada), sans égard aux règles de conflit de lois. Hide Nothing Keyboard et vous acceptez la juridiction exclusive de la province de Québec (Canada).",
            "Le présent accord, y compris les politiques présentées sur cette page Web, constitue l'intégralité du contrat entre les parties et il n'existe aucun autre accord ou représentation écrit ou verbal concernant les sujets décrits dans ce document.",
     "Si une disposition des présentes conditions d'utilisation est jugée invalide ou non exécutoire, ces dispositions seront dissociées du présent Contrat, mais ne porteront pas atteinte à la validité des autres dispositions."
        ]
    }
    ]},
        {name: "Garantie",
        text: [{
            p: [
                "En acceptant nos conditions d'utilisation, vous reconnaissez que vous comprenez et acceptez ce qui suit:",
                "Tous nos claviers sont fabriqués à la main avec la meilleure qualité de bois. Chaque planche de bois est différente, et puisque nous les construisons à la main, chaque clavier sera différent. Si vous commandez un clavier sur mesure, vous comprenez que nous ne pouvons pas prédire la couleur ou l'aspect exact de la plaque de bois, car il variera en fonction de chaque pièce de bois. Vous comprenez aussi que tous nos claviers sont parfaitement imparfaits, en ce sens qu'ils ne sont pas fabriqués en usine par des marches, et peuvent présenter différents angles de ponçage ou finition des bords. C'est ce qui rend chacun de nos clavier unique. Une variation mineure de couleur, de teinte, de grain du bois, de texture ou de forme ne sera pas considérée comme un défaut.",
                "Tous nos claviers et composants sont couverts par une garantie limitée d'un an à compter de la date d'expédition. La présente garantie couvre les défauts de fabrication ou de matériaux dans le cadre d'une utilisation normale.",
                "Une preuve d'achat valide sera exigée afin de soumettre toute demande de garantie.",
                "Pour résoudre les réclamations de garantie, nous allons d'abord travailler avec vous pour identifier la source du problème et confirmer qu'il n'est pas lié à une mauvaise utilisation ou un abus de votre part. Nos premiers efforts seront alors dirigés vers la résolution du problème, soit en réparant ou en envoyant des pièces de rechange. Si le problème est du côté logiciel, il peut vous être demandé de suivre différentes étapes, y compris l'installation d'un nouveau firmware sur votre clavier. Si nous ne pouvons pas régler le problème et que votre clavier est encore en période de garantie, nous le remplacerons.",
                "Si un problème survient en dehors de la période de garantie, nous vous encourageons à entrer en contact avec nous, et nous ferons de notre mieux pour aider. Chaque situation sera examinée au cas par cas, bien que la résolution ne soit pas assurée.",
		"Le client assume tous les frais et la responsabilité de l'expédition de retour",
                <a href="mailto:info@hnkbd.ca"> Si vous avez des questions, des problèmes de garantie ou des plaintes concernant votre clavier, veuillez nous contacter à info@hnkbd.ca.</a>,
            ]
        }]},
        {name: "Politique d'annulation",
        text: [{
            p: [
                "Vous pouvez demander l'annulation de votre commande à tout moment avant que les produits achetés ne soient expédiés. Pour les produits en stock, vous êtes admissible à un remboursement complet sans questions jusqu'à la date de transfert de votre commande au transporteur. Pour des calviers sur mesure, nous ne pouvons pas annuler ou rembourser votre commande si nous avons commencé à acheter des composants ou à construire votre clavier.", 
                "Des frais d'annulation et de remboursement peuvent s'appliquer au processeur de paiement. Ces frais sont à la charge du client et seront déduits du montant de la déclaration."
            ]
        }]},
        {name: "Politique de retour",
        text: [{
            p: [
                "Nous offrons une politique de retour de 30 jours pour les claviers en stock et les autres composants. Malheureusement, nous ne pouvons pas accepter de retour pour le clavier personnalisé. Veuillez communiquer avec afin que nous puissions voir ensemble comment résoudre votre insatisfaction.", 
                " Pour être admissible à un retour, l'article doit être dans son état d'origine et retourné dans son emballage d'origine.",
                "Le client assume tous les frais et la responsabilité de l'expédition de retour ",
                <a href="mailto:info@hnkbd.ca">Pour demander un retour, veuillez nous envoyer un courriel à info@hnkbd.ca en indiquant les renseignements suivants</a>,
                <ul>
                    <li>Votre nom et votre courriel</li>
                    <li>L'item que vous souhaitez retourner, avec le numéro de commande.</li>
                    <li>La raison du retour</li>
                </ul>
            ]
        }]},
        {name: "Politique de remboursement",
        text: [{
            p: [
                "Après réception et inspection votre retour, nous confirmerons par courriel l'approbation ou le rejet du remboursement (voir la garantie et les politiques de retour pour plus de détails)." ,
                "Le remboursement sera émis de notre part dans les 5 jours ouvrables. Le remboursement ne couvre pas les frais d'expédition, ni les autres droits de douane, taxes à l'importation, ou quelconque autre frais."
        ]
        }]},
        {name: "Politique de livraison",
        text: [
            {
            p:[
                "Nous travaillons sans relâche pour vous envoyer votre clavier le plus rapidement possible. Pour les claviers en stock ou des composants, nous traiterons et expédierons votre commande dans les 5 jours ouvrables. Pour un clavier sur mesure, si vous avez construit votre clavier à partir de pièces que nous avons déjà en stock, vous pouvez vous attendre à un délai de 2 à 4 semaines pour que nous construisions soigneusement votre oeuvre d'art. Le délai sera probablement plus proche de la partie la plus longue du spectre si vous avez demandé des commutateurs lubrifiés. Si vous avez fait une demande spéciale pendant la construction, le délai sera plus long car nous devons travailler avec différents fournisseurs pour obtenir les composants exacts qui correspondent à vos besoins. Les commandes personnalisées de ce genre sont généralement complétées dans un délai de 4 à 6 semaines, mais le temps peut varier selon la demande.",
		"La vitesse d'expédition et le coût de l'expédition dépendront de votre destination et du mode d'expédition choisi lors du processus de paiement.",
		"Pour assurer le bon déroulement de votre commande, veuillez vérifier votre adresse. Si un colis est retourné à l'expéditeur, nous vous contacterons pour obtenir les informations correctes. Vous serez responsable du paiement de la réexpédition. Hide Nothing Keyboard n'assume aucun coût lié à des renseignements erronés ou incorrects sur l'expédition.",
                "Si votre commande contient plusieurs articles, dont un clavier personnalisé, nous allons préparer le tout en une seule expédition, lorsque tous les articles seront prêts."
            ]
            },
            {
            h2: "Livraisons internationales",
            p: [
                "Nous expédions du Québec, au Canada. Si vous êtes un client international, vous acceptez par la présente que vous avez la responsabilité d'être au courant des lois de douane et d'importations pour votre pays. Tous les droits, taxes, TVA et frais liés aux services d'expédition sont à la charge du client. Hide Nothing Keyboard n'est pas responsable du paiement de ces frais. Si, pour une raison quelconque, l'envoi entraîne des frais d'expédition supplémentaires (par exemple: rejet par les douanes, adresse de livraison incorrecte, retour à l'expéditeur), les frais additionnels devront être pris en charge par l'acheteur." ,
                "Nous utilisons Stripe comme agent de traitement des paiements, ce qui permet une certaine conversion de devise, mais nous ne pouvons pas assurer la conversion à chaque devise. Hide Nothing Keyboard n'est pas responsable des frais de conversion."
             ]
            },
            {
            h2: "Colis perdus ou non livrés",
            p: [
                "Si un problème survient après que nous ayons transféré le colis au transporteur, le client est responsable de résoudre le problème avec le transporteur. Hide Nothing Keyboard n'est pas responsable des colis perdus ou non livrés.",
                "Vous pouvez toujours communiquer avec nous à info@hnkbd.ca pour obtenir des renseignements sur votre commande."
                ]
            }
        ]},
        {name: "Politique de confidentialité",
        text: [
            {p:[
                "La présente politique de confidentialité décrit comment vos renseignements personnels sont recueillis, utilisés et partagés lorsque vous visitez ou effectuez un achat sur notre site web hnkbd.ça. Cette politique s'applique aux sites Web de Hide Nothing Keyboard. Les produits et services de tiers ou de certaines sociétés affiliées sont assujettis à leurs propres politiques de confidentialité."
            ]},
            {
            h2:"Informations d'utilisation et informations personnelles",
            p:[
                "Lorsque vous visitez notre site Web, nous recueillons automatiquement certaines informations sur votre appareil. Cela peut inclure des données sur votre navigateur, votre adresse IP, votre fuseau horaire et les cookies. Nous pouvons également recueillir des renseignements sur votre expérience de navigation, notamment les pages ou les produits que vous avez consultés, le temps passé sur chaque page, les sites Web ou les termes de recherche qui vous ont dirigé vers le site et la façon dont vous interagissez avec le site.",
                "Nous utilisons différentes technologies pour recueillir des renseignements, notamment les cookies et les fichiers de journalisation. Vous trouverez plus d'informations sur les cookies en visitant http://www.allaboutcookies.org"
            ]},
            {
                h2:"Information d'achat",
                p:[
                    "Nous utilisons Stripe comme agent de traitement des paiements. Nous vous encourageons à consulter leur politique de confidentialité. https://stripe.com/fr-ca/privacy. Hide Nothing Keyboard ne recueille aucune information sur votre carte de crédit. Ces informations sont envoyés directement à Stripe sans que nous puissions les consulter, en conformité avec la réglementation PCIe. Nous recueillons toutefois les renseignements nécessaires pour traiter et expédier votre commande, tels que :",
                    <ul>
                        <li>Votre courriel</li>
                        <li>Votre adresse</li>
                        <li>Votre téléphone</li>
                    </ul>
                ]},
            {
            h2:"Utilisation des données personnelles",
            p:[
                "Nous utilisons les informations que nous recueillons pour vous fournir nos services, ce qui inclut l'exécution de votre commande, et pour communiquer avec vous si nous avons des détails à confirmer. Nous utilisons également des données personnelles pour le support client si nécessaire. Si vous avez fait une demande spéciale pour une demande sur mesure, nous pouvons communiquer avec vous par courriel pour obtenir différentes confirmations.",
                "Nous utilisons également les informations de navigation et d'appareil pour la prévention de la fraude, le dépannage technique et principalement pour optimiser votre expérience de navigation (par exemple en obtenant des informations sur l'habitude de nos clients via Google analytics)",
                "Nous pouvons également utiliser les informations personnelles et d'utilisation collectées pour :",
                <ul>
                    <li>Nous soumettre à une obligation légale</li>
                    <li>Protéger ou défendre nos droits et/ou notre propriété</li>
                    <li>Prévenir ou étudier les éventuels actes fautifs liés au Service</li>
                    <li>Protéger la sécurité personnelle des utilisateurs du service ou du public</li>
                    <li>Protéger Hide Nothing Keyboard contre la responsabilité légale</li>
                </ul>

            ]},
            {
            h2:"Politique de confidentialité des tiers",
            p:[
                "Si vous souhaitez plus d'informations sur les différentes politiques de confidentialité des tiers, nous vous invitons à consulter liens suivants",
                <a href="https://policies.google.com/privacy" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=fr-CA</a>,
                <a href="https://stripe.com/en-ca/privacy" target="_blank"
                rel="noopener noreferrer">https://stripe.com/fr-ca/privacy</a>,
            ]}
        ]}
    ]
}

// Voir site de camellia pour la version francaise !!!

export {policiesSourceTextENG, policiesSourceTextFR}
