import './modal.css';

function Modal(props){
    return(
        <div className='utilitie_warningModal modalHiddenUP' id='Modal'>
        {/* <h1>{props.title}</h1> */}
        <p>{props.text}</p>
        </div>  
    )
}

function ModalLoader(props){
    return(
        <div className='utilitie_warningModal modalLoaderActive modalHiddenUP' id='ModalLoader'>
        {/* <h1>{props.title}</h1> */}
        <p >{props.text}</p>
        </div>  
    )
}

export {Modal, ModalLoader}