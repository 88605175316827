import React, { useEffect, useState, useContext, useTransition } from "react";
import { Navigate, Route, Routes, useOutletContext } from "react-router-dom";
import {MainPage} from './Pages/MainPage/MainPage.js'
import {Cart} from './Pages/Cart/Cart.js'
import {CartReturn} from './Pages/Cart/CartReturn.js'
import './App.css';
import { InStockPage } from "./Pages/InStock/InStock.js";
import { ComponentsPage, ComponentsDetailsPage } from "./Pages/Components/Components.js";
import { BYO } from "./Pages/BYO/BYO.js";
import { AboutUsPage } from "./Pages/AboutUs/AboutUs.js";
import { OurCreations } from "./Pages/OurCreations/OurCreations.js";
import { OwnersPage } from "./Pages/OwnersPage/OwnersPage.js";
import { PoliciesPage } from "./Pages/Policies/Policies.js";
import { CheckoutPage } from "./Pages/Checkout/Chekcout.js";
import { NavBar } from './Components/NavBar.js';
import {Footer} from './Components/Footer.js'
import { SideNavBar } from './Components/SideNavBar.js';
import { ScrollToTop } from "./Components/ScrollToTop.js";
import { backendURLContext } from '../src/index.js'
import { imgArray } from "./images/imagesArray.js";
import { Modal, ModalLoader } from "./Components/Modal.js";
import componentBgSwitches from './images/Switches_main_4.jpg'
import componentBgWE from './images/woodEssence_main_3.jpg'
import componentBgPCB from './images/PCB_main.jpg'
import componentBgplate from './images/plate_main.jpg'
import componentBgCable from './images/cable_main.jpg'
import componentBgkeycaps from './images/keycaps_main.jpg'
import componentBgstabs from './images/stabs_001_3.jpg'




// React multi carousel: https://www.npmjs.com/package/react-multi-carousel


function App() {
      const urls = useContext(backendURLContext);
      const backendURL = urls.backend;
      const frontendURL = urls.frontend;
      const [dataArray, setDataArray] = useState([]);
      const [textDataArray, setTextDataArray] = useState([]);
      const [ready, setReady] = useState(false);
      const [cart, setCart] = useState({_itemCount: 0, _buildRequest:[]}); 
      const [displayLang, setDisplayLang] = useState("ENG");
      const [displayLangLong, setDisplayLangLong] = useState("English");
      const [displayCurr, setDisplayCurr] = useState("USD");
      const [modalText, setModalText] = useState("");
      const [modalLoaderText, setModalLoaderText] = useState("");
      const [checkoutBtnDisabled, setCheckoutBtnDisabled] = useState(true);
      const [updateShippingOptions, setUpdateShippingOptions] = useState(false);
      const [showLoader, setShowLoader] = useState(true);

      useEffect(() => {
            async function load(){
                  let existCart = localStorage.getItem("cart")? true:false;
                  if (existCart == false){ 
                        console.log("dans if cartExist, use effect du app.js");
                        // do nothing
                  } else {
                        let existingCart = await getLocalStorageData('cart');
                        console.log( "Dans use effet, local storage cart: " + existingCart, " uuid: " + existingCart._UUID);
                        setCart(existingCart);
                  }      
                  await getBackendData();
                  await getText();
                  await getLang();
            }

            load();
      }, [])

      const getBackendData = async()=>{
            let imgTarget = {};
            // console.log("dans callBackEndGetInStockKBD, debut, backendurl: "+ backendURL);
            let updateSucessfull = false;
            await fetch(backendURL+"/content/getData")    
            .then((resObject)=>{
                  console.log("dans le .then");
                  if(!resObject.ok){
                        return resObject.text();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  console.log("dans app.js, a la fin du callBakendGetInStockKBD, ")
                  //merge with picture array
                  for (const section in res){
                        // console.log("section in res: " + JSON.stringify(res[section]));
                        for (const subArray in res[section]){
                              // console.log("section subarray" + JSON.stringify(res[section][subArray]));
                              res[section][subArray].map((product)=>{
                                    // console.log("dans map du res section, id " + product._id);
                                    imgTarget = imgArray.find((e)=> e._id == product._id);
                                    if(imgTarget === undefined){}//Do nothing, means there's no pictures in imgArray for that product
                                    else{
                                          product._coverPicture = imgTarget._coverPicture;
                                          product._pictures = imgTarget._pictures;
                                    } 
                              })
                        }
                  }
                  // console.log("a la fin du map"+ JSON.stringify(res));
                  setDataArray(res)
                  // setReady(true);
            }) 
      }

      const getText = async()=>{
            // console.log("dans callBackEndGetInStockKBD, debut, backendurl: "+ backendURL);
            let updateSucessfull = false;
            await fetch(backendURL+"/content/getText")    
            .then((resObject)=>{
                  if(!resObject.ok){
                        return resObject.text();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  setTextDataArray(res[0])
                  setReady(true);
                  // console.log("returned Text: " + JSON.stringify(res[0]));
            }) 
      }

      const getLang=async()=>{
            let existSelectedLang = localStorage.getItem("selectedLang")? true:false;
            let browserLang = navigator.language.substring(0,2).toLowerCase();
            let selectedLang = "";

            if (existSelectedLang){
                  setDisplayLang(localStorage.getItem("selectedLang"))
            } else {
                  browserLang == "fr"? selectedLang ="FR": selectedLang ="ENG"
                  setDisplayLang(selectedLang)
                  localStorage.setItem('selectedLang', selectedLang);
            }


      }

      const getLocalStorageData= async(item)=>{
            let data = localStorage.getItem(item);
            console.log("dans get local storage, item: " + item + " data: " + data + " data parsed: "
            + JSON.parse(data));
            return (JSON.parse(data));
      }

      async function createCart(){
            let updateSucessfull = false;
            let newCart = {};
            console.log("dans createCart");
            await fetch(backendURL+"/cart/createCart", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'displayLang':displayLang,
                    'displayCurr':displayCurr
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              console.log("a la fin, dans le local storage: " + JSON.stringify(res));
              newCart = res;
            })
                
            return(newCart);
      }

      const addToCart=async(newItemData)=>{
            let localStorageCart = {};
            //Check if cart exist in local storage, if not call backend and store in local storage AND in state
            let existCart = localStorage.getItem("cart")? true:false;
            console.log("exist cart? " + existCart);
            if (existCart == false){ //call backend to get cart object
                  localStorageCart = await createCart();
            } else {
                  localStorageCart = await getLocalStorageData('cart');
                  console.log( "local storage cart: " + localStorageCart, " uuid: " + localStorageCart._UUID);
                  setCart(localStorageCart);
            }

            // call backend to add item
            let updateSucessfull = false;
            console.log("dans addToCart, cartUUID: " + localStorageCart._UUID);
            fetch(backendURL+"/cart/addItem", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'UUID': localStorageCart._UUID,
                    'newItem':newItemData
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
            })

            animateModal(textDataArray._modal.addedToCart[displayLang], 1800)
      }

      const removeFromCart=(cartUUID, itemID)=>{
            console.log("dans remove from cart " + itemID);

            // call backend to remove item
            let updateSucessfull = false;
            fetch(backendURL+"/cart/removeItem", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}), 
                  body: JSON.stringify({
                    'UUID': cartUUID,
                    'itemID':itemID
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              animateModal(textDataArray._modal.removeFromCart[displayLang], 1500)
            })

      }

      const modifyCart=(property, newData)=>{
            // call backend to modify cart
            let updateSucessfull = false;
            console.log("dans modifyCart, property: " + property + " new data: " + JSON.stringify(newData));
            fetch(backendURL+"/cart/modifyCart", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                        'property': property,
                        'newData':newData
                  })
                  })  
            .then((resObject)=>{
                  if(!resObject.ok){
                  return resObject.json();
                  } else {
                  updateSucessfull = true;
                  return resObject.json();
                  }
            })
            .then((res)=>{
                  localStorage.setItem('cart',JSON.stringify(res));
                  setCart(res);
            })
      }

      const setShippingOptions=async(selectedOption)=>{
            // call backend to set Shipping option
            console.log("dans set shipping option, selected option: " + selectedOption);
            let updateSucessfull = false;
            fetch(backendURL+"/cart/setShippingOption", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'UUID': cart._UUID,
                    'selectedOptionUUID':selectedOption,
                    'curr':displayCurr
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              setCheckoutBtnDisabled(false);
              document.querySelector('#checkoutBtn').innerHTML = textDataArray._cart.ckechkoutBtn[displayLang];
            })
      }

      const resetShippingOptions=async()=>{
            // call backend to set Shipping option
            console.log("dans resetShippingOptions: ");
            let updateSucessfull = false;
            fetch(backendURL+"/cart/resetShippingOption", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'UUID': cart._UUID,
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              setCheckoutBtnDisabled(true);
              document.querySelector('#checkoutBtn').innerHTML = textDataArray._cart.ckechkoutBtnMsg[displayLang];
            })
      }

      const createBuild = async(UUID)=>{
            //call backend to push new build to cart       
            let updateSucessfull = false;
            console.log("dans createBuild, cartUUID: " + UUID);
            fetch(backendURL+"/cart/createBuild", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                        'UUID': UUID,
                  })
            })  
            .then((resObject)=>{
                  if(!resObject.ok){
                        return resObject.json();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  console.log("dans .then du createBuild");
                  localStorage.setItem('cart',JSON.stringify(res));
                  setCart(res);
            })

      }

      const updateBuild = async(target, newVal, group, targetID, detailID)=>{

            //call backend to update build     
            let updateSucessfull = false;
            console.log("dans updateBuild, cartUUID: " + cart._UUID);
            fetch(backendURL+"/cart/updateBuild", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                        'UUID': cart._UUID,
                        'target' : target,
                        'newVal': newVal, 
                        'group': group,
                        'targetID': targetID,
                        'detailID':detailID,
                        'lang':displayLang

                  })
            })  
            .then((resObject)=>{
                  if(!resObject.ok){
                        return resObject.json();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  localStorage.setItem('cart',JSON.stringify(res));
                  setCart(res);
            })

      }

      const animateModal = (text, showDelay)=>{
            setModalText(text);
            let modal = document.querySelector('#Modal')
            modal.classList.remove('modalHiddenUP')
            setTimeout(()=>{
                  modal.classList.add('modalHiddenOpacity')
            }, showDelay)
            setTimeout(()=>{
                  modal.classList.add('modalHiddenUP')
            }, (showDelay+2000))
            setTimeout(()=>{
                  modal.classList.remove('modalHiddenOpacity')
            }, (showDelay+3000))
      }

      const animateModalLoader = (text)=>{
            setModalLoaderText(text);
            let modal = document.querySelector('#ModalLoader')
            modal.classList.remove('modalHiddenUP')
      }

      const clearModalLoader = ()=>{
            let modal = document.querySelector('#ModalLoader')
            modal.classList.add('modalHiddenUP')
      }


      return (
            <>
            {ready?
            <>
                  <ScrollToTop/>
                  <NavBar 
                        cartCount={cart._itemCount} 
                        animateModal={animateModal} 
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                        setDisplayLang={setDisplayLang}
                        setDisplayLangLong={setDisplayLangLong}
                        setDisplayCurr={setDisplayCurr}
                        displayCurr={displayCurr}
                        displayLangLong={displayLangLong}
                        resetShippingOptions={resetShippingOptions}
                        setUpdateShippingOptions = {setUpdateShippingOptions}
                        />

                   <Modal 
                        text={modalText}
                  /> 
                  <ModalLoader 
                        text={modalLoaderText}
                        
                  />
                  <Routes>
                        <Route index element={<MainPage 
                              dataArray={dataArray} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang} 
                              displayCurr={displayCurr}
                        />}/>
                        <Route exact path="/" element={<MainPage 
                        />}/>
                        <Route exact path="/cart" element={<Cart 
                              cart={cart} 
                              curr={displayCurr}
                              removeFromCart={removeFromCart}
                              setShippingOptions = {setShippingOptions}
                              setCart={setCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              btnEnable={checkoutBtnDisabled}
                              animateModal={animateModal}
                              updateShippingOptions = {updateShippingOptions}
                              setUpdateShippingOptions = {setUpdateShippingOptions}
                              animateModalLoader = {animateModalLoader}
                              clearModalLoader = {clearModalLoader}
                              />}
                        />
                        <Route exact path="cart/cartreturn" element={<CartReturn 
                              cartUUID={cart._UUID}
                              setCart={setCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                        />}/>
                        <Route exact path="/checkout" element={<CheckoutPage 
                              cartUUID={cart._UUID}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              />} />
                        <Route exact path="/instock" element={<InStockPage  
                              dataArray = {dataArray.kbdArray.inStock} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              />}/>
                        <Route exact path="/aboutus" element={<AboutUsPage 
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              />}/>
                        <Route exact path="/components" element={<ComponentsPage 
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              />}/>
                        <Route exact path="/components/wood" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsWoodEssences.title[displayLang]}
                              subTitle ={textDataArray._componentsWoodEssences.subTitle[displayLang]} 
                              text = {textDataArray._componentsWoodEssences.text[displayLang]} 
                              id = "componentSubpage_WE"
                              dataArray = {dataArray.componentsArray.woodEssence}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgWE}
                              />}

                        />
                        <Route exact path="/components/pcb" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsPCB.title[displayLang]}
                              subTitle ={textDataArray._componentsPCB.subTitle[displayLang]} 
                              text = {textDataArray._componentsPCB.text[displayLang]} 
                              id = "componentSubpage_PCB"
                              dataArray = {dataArray.componentsArray.PCB}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgPCB}
                              />}
                        />
                        <Route exact path="/components/plate" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsPlate.title[displayLang]}
                              subTitle ={textDataArray._componentsPlate.subTitle[displayLang]} 
                              text = {textDataArray._componentsPlate.text[displayLang]} 
                              id = "componentSubpage_Plate"
                              dataArray = {dataArray.componentsArray.plate}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgplate}
                              />}
                        />
                        <Route exact path="/components/switches" element={<ComponentsDetailsPage 
                              title = {textDataArray._componentsSwitches.title[displayLang]}
                              subTitle ={textDataArray._componentsSwitches.subTitle[displayLang]} 
                              text = {textDataArray._componentsSwitches.text[displayLang]} 
                              id = "componentSubpage_Switches"
                              dataArray = {dataArray.componentsArray.switches}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgSwitches}

                              />}
                              
                        />
                        <Route exact path="/components/keycaps" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsKeycaps.title[displayLang]}
                              subTitle ={textDataArray._componentsKeycaps.subTitle[displayLang]} 
                              text = {textDataArray._componentsKeycaps.text[displayLang]} 
                              id = "componentSubpage_Keycaps"
                              dataArray = {dataArray.componentsArray.keycaps}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgkeycaps}
                              />}/>
                        <Route exact path="/components/stabs" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsStabs.title[displayLang]}
                              subTitle ={textDataArray._componentsStabs.subTitle[displayLang]} 
                              text = {textDataArray._componentsStabs.text[displayLang]} 
                              id = "componentSubpage_Stabs"
                              dataArray = {dataArray.componentsArray.stabs}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgstabs}
                              
                        />}/>
                        <Route exact path="/components/cable" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsCable.title[displayLang]}
                              subTitle ={textDataArray._componentsCable.subTitle[displayLang]} 
                              text = {textDataArray._componentsCable.text[displayLang]} 
                              id = "componentSubpage_Cable"
                              dataArray = {dataArray.componentsArray.cable}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgCable}
                        />}/>
                        <Route exact path="/buildyourown" element={<BYO
                              updateBuild = {updateBuild}
                              createBuild = {createBuild}
                              createCart = {createCart}
                              getLocalStorageData = {getLocalStorageData}
                              setCart={setCart}
                              cartUUID={cart._UUID}
                              animateModal = {animateModal}
                              animateModalLoader = {animateModalLoader}
                              clearModalLoader = {clearModalLoader}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              />}/>
                        <Route exact path="/ourCreations" element={<OurCreations 
                              dataArray={dataArray.kbdArray.pastCreation} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              displayCurr={displayCurr}
                              />}/>
                        <Route exact path="/ownersPage" element={<OwnersPage 
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              />}/>
                        <Route exact path="/policies" element={<PoliciesPage 
                              textDataArray={textDataArray}
                              displayLang={displayLang}
                              />}/>
                  </Routes>
                  <SideNavBar 
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                        />

                  <Footer 
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                  /> 
            </>
            :
            <Loader 
                  textDataArray={textDataArray}
                  displayLang={displayLang}
                  />
            }
            
            </>
      );
}

function Loader(){
      return(
            <></>
      )
}

// function Email(){   To delete, used only to build email frame and see the result in a webPage
//       return(
//             <div style={{color:'rgb(56,34,15)', display:'flex', flexDirection:'column', alignItems:'flex-start',
//             textAlign:'justify', width:'80%', marginLeft:'10%', padding:'2rem 2rem', backgroundColor:'rgb(219,193,172)'}}>
//                    <img src={require('./images/logoFinal_small.png')}  alt="HNK"/>
//                   <h2>Custom build request</h2>
//                   <p style={{alignSelf:'flex-start', fontSize:'1.5rem'}}>Hi,</p>
//                   <p style={{fontSize:'1.5rem'}} >Good news, we have received your request for a custom build. Our team will analyse it and
//                         get back to you as soon as possible. 
//                   </p>
//                   <p style={{fontSize:'1.5rem'}}>Thanks for reaching out and congradulation on your bold moove. Your exceptionnal keyboad is a few steps away. You can always head back to your cart and see the status of your request</p>
//                   <a href="https://wwww.localhost:3000" style={{alignSelf:'center', backgroundColor:'rgba(99,72,50,1)', 
//             color:'rgb(236,224,209)', textDecoration:'none', padding:'0.5rem 1rem', borderRadius:'5px', fontSize:'2rem'}} >My cart</a>
//                   <p style={{fontSize:'1.5rem'}}>In the meantime, if you have any questions, you can always contact us by email</p>
//                   <a href="mailto:info@hnkbd.ca" style={{alignSelf:'center', backgroundColor:'rgba(99,72,50,1)', 
//             color:'rgb(236,224,209)', textDecoration:'none', padding:'0.5rem 1rem', borderRadius:'5px', fontSize:'2rem'}} >Contact us</a>
//                   <p style={{fontSize:'1.5rem'}}>The HNK team</p>
//             </div>
//       )
// }

//Todo: donner a chaque ComponentsDetailPage un ID, et dans le component.css, venir ajuster l'image de fond

export {App}
