import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './productOverlay.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// ==================== KEYBOARD ===========================
function ProductOverlay(props){
    const [displayPos, setDisplayPos] = useState(1);
    let textDataArray = props.textDataArray, displayLang = props.displayLang;

    const handleClick = (e)=>{
        // do nothing to prevent window from closing from background closing behavior
        e.stopPropagation();
      }

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        //   partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    
    return(
        <div id="producOverlayContainer" onClick={(e)=>{props.onClick(false)}}>
            <div id="producDetailsContainer" onClick={handleClick}>
                <div id='productDetailsCarousel'>
                    <Carousel 
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        partialVisible={false}
                        centerMode={false}
                    >
                        {props.kbdData._pictures.map((img)=>{
                            return(
                                <img src={img} alt="keyboard picture"/>
                            )
                        })}
                    </Carousel>
                </div>
                <div id='producDetailsInfo'>
                    <ul>
                        <li>  {/* Form factor */}
                            <img src={require('../images/Icon_Keyboard_C5_4911712.png')} alt="Form Factor"/>
                            {props.kbdData._formFactor[displayLang]}
                        </li>
                        <li>{/* Wood */}
                            <img src={require('../images/Icon_Wood_C5_3928237.png')} alt="Backplate wood"/>
                            {props.kbdData._woodEssence[displayLang]}
                        </li> 
                        <li>{/* Plate */}
                            <img src={require('../images/Icon_Plate_C5_4033663.png')} alt="Plate"/>
                            {props.kbdData._plate[displayLang]}
                        </li> 
                        <li>{/* Switches and lube */}
                            <img src={require('../images/Icon_Switch_C5_2987081.png')} alt="Switch"/>
                            {props.kbdData._switches[displayLang]}
                        </li>
                        <li>{/* Stabs */}
                        <img src={require('../images/Icon_Stabs_C5_4965548.png')} alt="Keycaps"/>
                            {props.kbdData._stabs[displayLang]}
                        </li>
                        <li>{/* Keycaps */}
                            <img src={require('../images/Icon_Keycap_C5_3612734.png')} alt="Keycaps"/>
                            {props.kbdData._keycaps[displayLang]}
                        </li>
                        <li>{/* USB cable */}
                            <img src={require('../images/Icon_USB_C5_1495628.png')} alt="USB cable"/>
                            {props.kbdData._cable[displayLang]}
                        </li>
                        <li>{/* Price */}
                            <img src={require('../images/Icon_PriceTag_C5_6779898.png')} alt="$$"/>
                            {props.kbdData._price[props.displayCurr]} {props.textDataArray._displayCurr.currSign[props.displayCurr]}
                        </li>
                    </ul>
                    <div id='productDetailsDescription'>
                        {props.kbdData._description[displayLang]}
                        <br/><br/>
                        {textDataArray._productOverlay.acc[displayLang]}
                        {props.kbdData._accessories[displayLang]}
                    </div>
                    <div id='actionBar'>
                        <img src={require('../images/Icon_Close_C5_40378.png')} alt="Close" onClick={(e)=>{props.onClick(false)}}/>
                        <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart" onClick={(e)=>{props.addToCart(props.kbdData)}}/>
                    </div>
                </div>
            </div>
        </div>
    )
  };


  // =================== COMPONENT ===========================
function ProductOverlayComponent(props){
    const [displayPos, setDisplayPos] = useState(1);
    let textDataArray = props.textDataArray, displayLang = props.displayLang;

    const handleClick = (e)=>{
        // do nothing to prevent window from closing from background closing behavior
        e.stopPropagation();
      }

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        //   partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    
    return(
        <div id="producOverlayContainer" onClick={(e)=>{props.onClick(false)}}>
            <div id="producDetailsContainer" onClick={handleClick}>
                <div id='productDetailsCarousel'>
                    <Carousel 
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        partialVisible={false}
                        centerMode={false}
                    >
                        {props.componentData._pictures.map((img)=>{
                            return(
                                <img src={img} alt="keyboard picture"/>
                            )
                        })}
                    </Carousel>
                </div>
                <div id='producDetailsInfo'>
                    <div id='productDetailsDescription'>
                        <ul>
                            <li>
                                {props.componentData._description[displayLang]}
                            </li>
                            {props.componentData._specs[displayLang].map((specBullet)=>
                                <li>
                                <img src={require('../images/Icon_Bullet_C5_4084269.png')}  alt="Price"/>
                                {specBullet}
                                </li>
                            )}
                            <li>{/* Price */}
                                <img src={require('../images/Icon_PriceTag_C5_6779898.png')} alt="$$"/>
                                {props.componentData._price[props.displayCurr]} {props.textDataArray._displayCurr.currSign[props.displayCurr]}
                            </li>
                        </ul>
                    </div>
                    <div id='actionBar'>
                        <img src={require('../images/Icon_Close_C5_40378.png')} alt="Close" onClick={(e)=>{props.onClick(false)}}/>
                        <img src={require('../images/Icon_CartAdd_C5_6701644.png')} alt="Add to cart" onClick={(e)=>{props.addToCart(props.componentData)}}/>
                    </div>
                </div>
            </div>
        </div>
    )
  };
  export {ProductOverlay, ProductOverlayComponent}