import React, { useCallback, useState, useEffect, useContext } from "react";
// stripe components
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
//other components
import { backendURLContext } from '../index.js'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
// const stripePromise = loadStripe("pk_test_51PMJ7R00Dw5VTVXxWG4vKYyliNyqiHG0JHxRameE9knxCzMYKwLldSSRpLmC87AkzJEs5m83FYh4TwkCDqllew1T00QxA3o6Y3"); //Test
//Prod public API Key
const stripePromise = loadStripe("pk_live_51PMJ7R00Dw5VTVXxXmHmllQc4PwQAAS8OxFEyFKsEZuXDp6NiFFWIM9tGl0P6WC96L2H6Vd04fXzhXEfjbvXjl2Q00ZA0SrOw3");  //Prod

const CheckoutForm = ({cartUUID, textDataArray, displayLang, displayCurr}) => {
  console.log("dans checkoutForm, cart: " + cartUUID);

  const urls = useContext(backendURLContext);
  const backendURL = urls.backend;
  const frontendURL = urls.frontend;
    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        console.log("dans fetchClientSecret");
        return fetch(backendURL+"/stripe/create-checkout-session", {
        method: "POST",
        headers: new Headers({'content-type':'application/json'}),
        body: JSON.stringify({
          // 'email': 'cart._destination_address.contact_email',
          cartUUID: cartUUID,
          selectedLang: displayLang,
          curr: displayCurr
        })
        })
        .then((res) => {
            console.log("dans res du fetch client secret");
            return res.json();
        })
        .then((data) => data.clientSecret);
    }, []);

    const options = {fetchClientSecret};

    return (
        <div id="checkout">
        <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
        >
            <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
        </div>
  )
}

const Return = ({cartUUID, setCart, textDataArray, displayLang}) => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const urls = useContext(backendURLContext);
  const backendURL = urls.backend;
  const frontendURL = urls.frontend;
  const tt = textDataArray._cartReturn;

  const test = ()=>{
    fetch(backendURL+"/cart/test")  
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    console.log("dans use effect du return, session id: " + sessionId);

    fetch(backendURL+`/stripe/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
        if (data.status == "complete"){
          //Call backend for all after purchase actions
          let updateSucessfull = false;
          fetch(backendURL+"/cart/completePurchase", {
                method: 'POST',
                headers: new Headers({'content-type':'application/json'}),
                body: JSON.stringify({
                  'UUID': cartUUID,
                })
              })  
          .then((resObject)=>{
            if(!resObject.ok){
                return resObject.json();
            } else {
              updateSucessfull = true;
              return resObject.json();
            }
          })
          .then((res)=>{
            console.log("Dans le .Then du use effet de cart return");
            localStorage.removeItem("cart")
            setCart(res);
          })
        }
      });
    }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>{tt.successP1[displayLang]}
           {customerEmail}.
        </p>
        <p>
        {tt.successP2[displayLang]} <a href="info@hnkbd.ca">info@hnkbd.ca</a>.
        </p>
      </section>
    )
  }

  return null;
}


export {CheckoutForm, Return}