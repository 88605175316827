import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './mainPage.css';
import './landingPage.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ProductOverlay } from '../../Components/ProductOverlay';
import { StandAloneCard } from '../../Components/CardContainer';

//pallette: https://www.color-hex.com/color-palette/30023//

//  Images ///
// import logoSmallWhite from "../../images/logoFinal_small_white.png";
// import KNoBG from '../../images/K0000_2.jpg';
// import KNoBG_1 from '../../images/K0000_3.jpg';
// import KBD0_1 from "../../images/Keyboard0_1.jpg";
// import KBD0_0 from "../../images/Keyboard0_2.jpg";
// import KBD0_2 from "../../images/Keyboard0_3.jpg";
// import KBD1_1 from "../../images/Keyboard0_3.jpg";
// import KBD2_1 from "../../images/Numpad0_2.jpg";
// import KBD3_1 from "../../images/PCB_plate2.jpg";
// import KBD4_1 from "../../images/Plate_aluminium_1.jpg";
// import Icon_kbd_w from '../../images/Icon_Keyboard_White.png'; //https://thenounproject.com/icon/keyboard-4911712/
// import Icon_plate_w from '../../images/Icon_Plate_White.png'; //https://thenounproject.com/icon/computer-keyboard-4033663/
// import Icon_switch_w from '../../images/Icon_Switch_White.png'; //https://thenounproject.com/icon/mechanical-keyboard-switch-2987081/
// import Icon_Keycap_w from '../../images/Icon_Keycap_White.png'; //https://thenounproject.com/icon/plus-key-3612734/
// import Icon_USB_w from '../../images/Icon_USB_White.png'; //https://thenounproject.com/icon/connector-1495628/
// import Icon_Wood_w from '../../images/Icon_Wood_White.png'; //https://thenounproject.com/icon/wood-log-3928237/
// import Icon_PriceTag_w from '../../images/Icon_PriceTag_White.png'; //https://thenounproject.com/icon/price-6779898/
// import Icon_CartAdd_w from '../../images/Icon_CartAdd_White.png'; //https://thenounproject.com/icon/cart-6701644/


function MainPage({dataArray, addToCart, textDataArray, displayLang, displayCurr}){  
    return(
        <main>
            <LandingPage 
                dataArray={dataArray} 
                addToCart={addToCart} 
                textDataArray={textDataArray}
                displayLang={displayLang}  
                displayCurr={displayCurr}
                />
        </main>
    )
  }
  
  function MainCarousel({textDataArray, displayLang}){
    let textTarget = textDataArray._landingPage;
      
    const scrollDown=()=>{
        const element = document.getElementById("LP_AboutUs");
        element.scrollIntoView();
    };

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        //   partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    
    return(
        <div id="mainCarousel" className='carouselContainer'>
            <Carousel 
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={1500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            partialVisible={false}
            centerMode={false}
            >

           <div className='carouselSlideContainer'>
                <img src={require('../../images/K0003_3.jpg')} alt="Hide Nothing"/>
            </div>
            <div className='carouselSlideContainer'>
                <img src={require('../../images/K0001_9.jpg')} alt="Hide Nothing"/>
            </div>
            <div className='carouselSlideContainer'>
                <img src={require('../../images/K0002_2.jpg')} alt="Hide Nothing"/>
            </div>
            <div className='carouselSlideContainer'>
                <img src={require('../../images/K0003_10.jpg')} alt="Hide Nothing"/>
            </div>
            <div className='carouselSlideContainer'>
                <img src={require('../../images/K0006_1.jpg')} alt="Hide Nothing"/>
            </div>
            <div className='carouselSlideContainer'>
                <img src={require('../../images/K0001_3.jpg')} alt="Hide Nothing"/>
            </div>

        </Carousel>
        <h1 className='carouselOverlayHeader'>{textTarget.mainCarouselH1[displayLang]}</h1>
        <div className='carouselOverlayText'>{textTarget.mainCarouselDiv[displayLang]}</div>

        <div className='actButton'>
            {/* <a className='btn' href='#LP_AboutUs'>Discover our keyboards</a>             */}
            <btn className="btn" onClick={scrollDown}>{textTarget.mainCarouselBtn[displayLang]}</btn>
        </div>
    </div>
    )
  }

  function OurCreationCarousel({kbdData, onClick, addToCart, textDataArray, displayLang, displayCurr}){
    let textTarget = textDataArray._landingPage;

      
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 2400 },
          items: 5,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        desktop: {
          breakpoint: { max: 2400, min: 1800 },
          items: 4,
        //   partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        other: {
            breakpoint: { max: 1800, min: 1024 },
            items: 3,
          //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        //   partialVisibilityGutter: 20 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    
    return(
        <div id="ourCreationCarousel" className='carouselContainer'>
            <h1 className='carouselOverlayHeader'>{textTarget.ourCreationsCarouselH1[displayLang]}</h1>
            <div className='carouselOverlayText'>{textTarget.OCDiv1[displayLang]}</div>
            
            <Carousel 
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            partialVisible={false}
            centerMode={false}
            >

           {kbdData.map((kbd)=>
                    <StandAloneCard 
                        kbdData={kbd} 
                        key={kbd._id} 
                        onClick={onClick} 
                        addToCart={addToCart} 
                        textDataArray={textDataArray}
                        displayCurr={displayCurr}
                        displayLang={displayLang}
                        />
                )}

        </Carousel>
        <div className='actButton'>
            <NavLink id="ourCreationCarouselBtn" className='btn' to="/ourCreations" >{textTarget.OCBTN[displayLang]}</NavLink>
        </div>
    </div>
    )
  }

  function LandingPage({dataArray, addToCart, textDataArray, displayLang, displayCurr}){
    const [showProducOverlay, setShowProducOverlay] = useState(false);
    const [targetKbd, setTargetKBD] = useState({})
    const handleClick=(kbd)=>{
        setTargetKBD(kbd);
        setShowProducOverlay(true);
    }
    const tt = textDataArray._landingPage;

    // console.log("dans landing page, tt: " + JSON.stringify(tt));
    return(
        <div id='landingPage'>
         {showProducOverlay? 
            <ProductOverlay 
                onClick={setShowProducOverlay} 
                kbdData={targetKbd}
                textDataArray={textDataArray}
                displayLang={displayLang}
                addToCart={addToCart}
                displayCurr={displayCurr}
                />:<></>}
        <MainCarousel    
            textDataArray={textDataArray}
            displayLang={displayLang}  
        />
        {/* J'ai hardcode les sections, creer un seul component avec des props */}
        <div id='LP_AboutUs' className='LP_Section'>
            <div className='LP_SectionTitle'>
                <h1>{tt.sectionTitleH1[displayLang]}</h1>
                <p>{tt.sectionTitleP[displayLang]}</p>
            </div>
            <div className='LP_CardContainer'>
                <img src={require('../../images/K0004_10.jpg')} alt="Hide Nothing"/>
                <div className='cardText'>
                        <h1>{tt.sectionCard1H1[displayLang]}</h1>
                        <p>{tt.sectionCard1P[displayLang]}
                        </p>
                        <div className='actButton'>
                        <NavLink className='btn' to="/instock" >{tt.sectionCard1Btn[displayLang]}</NavLink>
                        </div>
                </div>
            </div>
            <div className='LP_CardContainer'>
                <img src={require('../../images/PCB_002_1.jpg')} alt="custom Keyboard"/>
                <div className='cardText'>
                        <h1>{tt.sectionCard2H1[displayLang]}</h1>
                        <p>{tt.sectionCard2P[displayLang]}                          
                        </p>
                        <div className='actButton'>
                            <NavLink className='btn' to="/buildyourown" >{tt.sectionCard2Btn[displayLang]}</NavLink>
                        </div> 
                </div>
            </div>
            <div className='LP_CardContainer'>
                <img src={require('../../devImages/ian-schneider-IQbC4VU4YPQ-unsplash.jpg')} alt="woodWorking"/>
                 {/* https://unsplash.com/photos/person-touching-brown-plank-IQbC4VU4YPQ   */}
                <div className='cardText'>
                        <h1>{tt.sectionCard3H1[displayLang]}</h1>
                        <p> {tt.sectionCard3P1[displayLang]}</p>
                        <p> {tt.sectionCard3P2[displayLang]}</p>
                        <div className='actButton'>
                            <NavLink className='btn' to="/components/wood" >{tt.sectionCard3Btn[displayLang]}</NavLink>
                        </div>
                </div>
            </div>
        </div>

        <OurCreationCarousel 
            kbdData={dataArray.kbdArray.inStock} 
            onClick={handleClick}
            addToCart={addToCart}
            textDataArray={textDataArray}
            displayLang={displayLang} 
            displayCurr={displayCurr}
        />


                          

                                                      
        <div id='gatewayInStock' className='LP_Section gateway'>
            <div className='gatewayBG'></div>
            <h1>{textDataArray._navBar.pMenuIS[displayLang]}</h1>
            <NavLink className='gatewayNL' to="/instock" ></NavLink>
        </div>
        <div id='gatewayBYO' className='LP_Section'>
            <div className='gatewayBG'></div>
            <h1>{textDataArray._navBar.pMenuBYO[displayLang]}</h1>
            <NavLink className='gatewayNL' to="/buildyourown" ></NavLink>
        </div>
        <div id='gatewayComponents' className='LP_Section'>
            <div className='gatewayBG'></div>
            <h1>{textDataArray._navBar.pMenuComp[displayLang]}</h1>
            <NavLink className='gatewayNL' to="/components" ></NavLink>
        </div>
        <div id='gatewayAboutUs' className='LP_Section'>
            <div className='gatewayBG'></div>
            <h1>{textDataArray._navBar.aboutUs[displayLang]}</h1>
            <NavLink className='gatewayNL' to="/aboutus" ></NavLink>
        </div>
    </div>
    )
  }

  
  export {MainPage};
  
  
  /*TODO
  - Changer mon carousel maison pour le react multi carousel
  
  */

//   <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
//                 <img src={KNoBG_1} alt="keyboard1"/>
//                 <ul>
//                     <li>  {/* Form factor */}
//                         <img src={Icon_kbd_w} alt="Form Factor"/>
//                         65% RGB
//                     </li>
//                     <li>{/* Backplate */}
//                         <img src={Icon_Wood_w} alt="Backplate wood"/>
//                         Bubinga
//                     </li> 
//                     <li>{/* Plate */}
//                         <img src={Icon_plate_w} alt="Plate"/>
//                         FR4 plate - red
//                     </li> 
//                 </ul>
//             </div>
//             <div className="card" onClick={()=>{setShowProducOverlay(true)}}>
//                 <img src={KBD4_1} alt="keyboard1"/>
//                 <ul>
//                     <li>  {/* Form factor */}
//                         <img src={Icon_kbd_w} alt="Form Factor"/>
//                         65% RGB
//                     </li>
//                     <li>{/* Backplate */}
//                         <img src={Icon_Wood_w} alt="Backplate wood"/>
//                         Bubinga
//                     </li> 
//                     <li>{/* Plate */}
//                         <img src={Icon_plate_w} alt="Plate"/>
//                         FR4 plate - red
//                     </li> 
//                 </ul>
//             </div>
